import { Box, IconButton, Paper, Alert, Chip } from '@mui/material';
import Header from 'components/Header';
import Loading from 'components/Loading';
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import { useAPI } from 'contexts/APIProvider';
import { useNurse } from 'contexts/NurseProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import JobEmployment from 'logic/JobEmployment';
import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { nurseJobsURL, nurseViewJobEmploymentURL } from 'routes/urls';
import DescriptionList from 'components/DescriptionList';
import { isEmpty } from 'lodash';

const NurseEmploymentList: React.FC = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { nurse } = useNurse()
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [matches, setMatches] = React.useState<JobEmployment[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const date = React.useMemo(() => params.date ? DateTime.fromISO(params.date) : DateTime.now(), [params.date])

    const fetchMatches = React.useCallback(async () => {
        if (!nurse) return
        try {
            setLoading(true)
            setMatches(await api.listJobEmployments({
                nurse_id: nurse.nurse_id,
                from_date: date.toFormat('yyyy-LL-dd'), 
                to_date: date.plus({ days: 1 }).toFormat('yyyy-LL-dd'),
            }))
        } catch (error) {
            console.error(error)
            showAlert('error', 'Failed to fetch jobs')
        } finally {
            setLoading(false)
        }
    }, [api, date, nurse, showAlert])

    React.useEffect(() => {
        fetchMatches()
    }, [fetchMatches])
    
    function goBack() {
        if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
    }

    function goToMatch(match: JobEmployment) {
        if (nurse) navigate(nurseViewJobEmploymentURL(nurse.nurse_id, match.id))
    }

    if (!nurse || loading) return <Loading />
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Header variant='h2' text={date.toFormat('ccc, LLL dd')}>
            <IconButton onClick={goBack}>
              <CancelOutlined />
            </IconButton>
          </Header>
          {isEmpty(matches) ? (
            <Alert severity='info'>
              No jobs on this date yet.
            </Alert>
          ) : (
            matches.map(match => (
              <Paper key={match.id} onClick={() => goToMatch(match)} sx={{ cursor: 'pointer', padding: 1 }}>
                <DescriptionList labelWidth='40%' valueWidth='60%' data={[{
                  label: 'Practice',
                  value: match.practice_name,
                }, {
                  label: 'Time',
                  value: match.time_label(match.nurse_tz)
                }, {
                  label: 'Lunch Break',
                  value: match.lunch_break_label,
                }, {  
                  label: 'Status',
                  value: (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      <Chip 
                        size='small' 
                        label={match.fulfillment_status_label} 
                        color={match.fulfillment_status_color}/>
                      {match.payment_status ? (
                        <Chip 
                          size='small' 
                          label={match.payment_status_label} 
                          color={match.payment_status_color} />
                      ) : null}
                      {match.timesheet_status ? (
                        <Chip
                          size='small'
                          label={match.timesheet_status_label}
                          color={match.timesheet_status_color}/>
                      ) : null}
                    </Box>
                  )
                }]}></DescriptionList>
              </Paper>
            ))
          )}
        </Box >
    )
}

export default NurseEmploymentList