import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import React from 'react'
import NurseSignupContext from './context';
import { Link } from 'react-router-dom'
import Header from 'components/Header';
import StripeProgress from 'components/StripeProgress';
import { nurseJobsURL } from 'routes/urls'
import DocumentUpload from 'components/DocumentUpload';
import RightToWork from 'components/RightToWork';
import GDCRegistration from 'components/GDCRegistration';

const NurseSignupSummary: React.FC = () => {
  const { nurse, emitGTMEvent, notification$ } = React.useContext(NurseSignupContext)

  const nurseId = React.useMemo(() => nurse?.nurse_id, [nurse])
  const approved = React.useMemo(() => nurse?.approved ?? false, [nurse])
  const enabled = React.useMemo(() => nurse?.enabled ?? false, [nurse])

  React.useEffect(() => {
    emitGTMEvent('nurse-signup-summary')
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Paper sx={{
        padding: 1, display: 'flex', flexDirection: 'column', gap: 1
      }}>
        {approved ? (
          <React.Fragment>
            <Header variant='h3' text='Application Approved'></Header>
            <Typography variant='body1'>
              You may start taking jobs now.
            </Typography>
          </React.Fragment>
        ) : enabled ? (
          <React.Fragment>
            <Header variant='h3' text='Application Submitted'></Header>
            <Typography variant='body1'>
              We are processing your application. We will email you when everything is ready.
              Meanwhile, you may start browsing jobs, but you cannot take jobs yet.
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Header variant='h3' text='Application Submitted'></Header>
            <Typography variant='body1'>
              Locumloop will be launching really soon! We will let you know as soon as we are ready.
            </Typography>
          </React.Fragment>
        )}
        {nurseId && enabled ? (
          <Button variant="contained" color="primary"
            component={Link} to={nurseJobsURL(nurseId)}>Start Browsing Jobs</Button>
        ) : null}
      </Paper>
      <Header variant='h3' text='Application Status'></Header>
      <StripeProgress />
      {nurse ? (
        <React.Fragment>
          <RightToWork nurseId={nurse.nurse_id} notification$={notification$} />
          <GDCRegistration nurseId={nurse.nurse_id} notification$={notification$} />
          <DocumentUpload nurseId={nurse.nurse_id} fileType='indemnity_insurance' />
          <DocumentUpload nurseId={nurse.nurse_id} fileType='immunizations' />
          <DocumentUpload nurseId={nurse.nurse_id} fileType='dbs' />
          <DocumentUpload nurseId={nurse.nurse_id} fileType='cpr' />
          <DocumentUpload nurseId={nurse.nurse_id} fileType='infection_control' />
        </React.Fragment>
      ) : null}
    </Box>
  )
}

export default NurseSignupSummary