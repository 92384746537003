import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Tooltip,
  Chip,
  Link,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { useNurse } from 'contexts/NurseProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { isEmpty } from 'lodash'
import JobEmployment from 'logic/JobEmployment'
import React from 'react'
import { JobEmploymentPaymentStatus } from 'types/types'
import { nurseViewJobEmploymentURL } from 'routes/urls'

interface NursePaymentsTableProps {
  payment_statuses?: JobEmploymentPaymentStatus[]
}

const NursePaymentsTable: React.FC<NursePaymentsTableProps> = ({ payment_statuses }) => {
  const { nurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [records, setRecords] = React.useState<JobEmployment[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchRecords = React.useCallback(async () => {
    if (!nurse) return
    try {
      setLoading(true)
      setRecords(await api.listJobEmployments({ payment_statuses, nurse_id: nurse.nurse_id }))
    } catch (e) {
      showAlert('error', 'Error fetching payment records')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [api, nurse, payment_statuses, showAlert])

  const getPaymentStatusLabel = React.useCallback((record: JobEmployment) => {
    switch (record.payment_status) {
      case 'practice_paid':
      case 'practice_invoice_paid':
        return <Chip label='Paid' color='success' size='small' />
      case 'practice_payment_error':
        return (
          <Tooltip title={'There is an error processing payment. We will resolve this with the practice as soon as possible.'}>
            <Chip label='Payment Error' color='error' size='small' />
          </Tooltip>
        )
      case 'practice_invoice_void':
      case 'practice_invoice_payment_failed':
        return (
          <Tooltip title={'The practice failed to pay the invoice. We will resolve this with the practice as soon as possible.'}>
            <Chip label='Payment Error' color='error' size='small' />
          </Tooltip>
        )
      case 'practice_payment_pending':
        if (record.pay_by_invoice) {
          return <Chip label='Invoicing' color='primary' size='small' />
        } else {
          return <Chip label='Pending Payment' color='primary' size='small' />
        }
      case 'practice_payment_requested':
        return <Chip label='Payment Processing' color='primary' size='small' />
      case 'practice_invoice_finalized':
        return <Chip label='Invoice Sent' color='primary' size='small' />
      default:
        return <Chip label='N/A' size='small' />
    }
  }, [])

  React.useEffect(() => {
    fetchRecords()
  }, [fetchRecords])

  if (loading) {
    return <Loading />
  } else if (isEmpty(records)) {
    return <Alert severity='info'>No Records Found</Alert>
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Practice</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Lunch</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Earnings</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Schedule</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) => (
            <TableRow key={record.id}>
              <TableCell data-label='Practice'>{record.practice_name}</TableCell>
              <TableCell data-label='Date'>
                <Link component={RouterLink} to={nurseViewJobEmploymentURL(record.nurse_id, record.id)}>
                  {record.date_label(record.nurse_tz)}
                </Link>
              </TableCell>
              <TableCell data-label='Time'>{record.time_label(record.nurse_tz)}</TableCell>
              <TableCell data-label='Lunch Break'>{record.lunch_break_label}</TableCell>
              <TableCell data-label='Rate'>{record.hourly_rate_label}</TableCell>
              <TableCell data-label='Earnings'>{record.nurse_fees_label}</TableCell>
              <TableCell data-label='Status'>{getPaymentStatusLabel(record)}</TableCell>
              <TableCell data-label='Schedule'>{record.payment_schedule_for_nurse}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default NursePaymentsTable