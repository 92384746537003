import React from 'react'
import { Outlet, Route, Routes, Navigate, useLocation } from 'react-router'
import Layout from 'layouts/Layout'
import { PracticeProvider } from 'contexts/PracticeProvider'
import { NurseProvider } from 'contexts/NurseProvider'
import { JobProvider } from 'contexts/JobProvider'
import { JobEmploymentProvider } from 'contexts/JobEmploymentProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'
import IsPractice from './IsPractice'
import IsNurse from './IsNurse'
import IsAdmin from './IsAdmin'
import { loginURL, practiceJobsURL, nurseJobsURL, adminPracticeListURL } from './urls'
import Login from 'pages/Login'
import PracticeSignup from 'pages/PracticeSignup'
import NurseSignup from 'pages/NurseSignup'
import PracticeJobs from 'pages/PracticeJobs'
import PracticeViewJob from 'pages/PracticeJobs/ViewJob'
import PracticeEditJob from 'pages/PracticeJobs/EditJob'
import PracticeAddJob from 'pages/PracticeJobs/AddJob'
import PracticeEditDefaultJobOptions from 'pages/PracticeJobs/EditDefaultJobOptions'
import NurseJobs from 'pages/NurseJobs'
import NurseJobList from 'pages/NurseJobs/JobList'
import NurseTakeJob from 'pages/NurseJobs/TakeJob'
import NurseViewJobEmployment from 'pages/NurseJobs/ViewJobEmployment'
import NurseProfile from 'pages/NurseProfile'
import NurseProfileEdit from 'pages/NurseProfileEdit'
import PracticeBilling from 'pages/PracticeBilling'
import NurseBilling from 'pages/NurseBilling'
import ResetPassword from 'pages/ResetPassword'
import PrelaunchPage from 'pages/PrelaunchPage'
import NurseAchievements from 'pages/NurseAchievements'
import PracticeSavings from 'pages/PracticeSavings'
import PracticeProfileEdit from 'pages/PracticeProfileEdit'
import NurseCancelJobEmployment from 'pages/NurseCancelJobEmployment'
import PracticeCancelJob from 'pages/PracticeCancelJob'
import PracticeNotificationList from 'pages/NotificationList/Practice'
import NurseNotificationList from 'pages/NotificationList/Nurse'
import PracticeContractAgreement from 'pages/ContractAgreement/Practice'
import NurseContractAgreement from 'pages/ContractAgreement/Nurse'
import TimeSheetView from 'pages/TimeSheetView'
import TimeSheetProposeChange from 'pages/TimeSheetProposeChange'
import TimeSheetEditChange from 'pages/TimeSheetEditChange'
import PracticeSignupJobList from 'pages/PracticeSignup/JobPosting/JobList'
import PracticeSignupJobAdd from 'pages/PracticeSignup/JobPosting/JobAdd'
import PracticeSignupJobEdit from 'pages/PracticeSignup/JobPosting/JobEdit'
import NurseNotificationSettings from 'pages/NotificationSettings/nurse'
import NurseAffiliateSignup from 'pages/NurseAffiliateSignup'
import ReviewNurse from 'pages/ReviewNurse'
import PracticeBlacklist from 'pages/PracticeBlacklist'
import NurseDocumentsPage from 'pages/NurseDocumentsPage'
import PracticeMemberList from 'pages/PracticeMemberList'
import PracticeInvitedSignup from 'pages/PracticeInvitedSignup'
import { PracticeGroupProvider } from 'contexts/PracticeGroupProvider'
import IsPracticeGroup from 'routes/IsPracticeGroup'
import PracticeGroupPracticeList from 'pages/PracticeGroupPracticeList'
import PracticeGroupAddPractice from 'pages/PracticeGroupPracticeList/AddPractice'
import PracticeGroupBillingAccountList from 'pages/PracticeGroupBillingAccountList'
import PracticeGroupBillingAccountAdd from 'pages/PracticeGroupBillingAccountList/BillingAccountAdd'
import PracticeGroupBillingAccountEdit from 'pages/PracticeGroupBillingAccountList/BillingAccountEdit'
import { AffiliateProvider } from 'contexts/AffiliateProvider'
import IsAffiliate from 'routes/IsAffiliate'
import AffiliateTransactionList from 'pages/AffiliateTransactionList'
import AffiliateStripeSetup from 'pages/AffiliateStripeSetup'
import NurseAffiliatePage from 'pages/NurseAffiliatePage'
import NurseSuspensionPage from 'pages/NurseSuspensionPage'
import ReviewDocuments from 'pages/PracticeJobs/ReviewDocuments'
import { AdminProvider } from 'contexts/AdminProvider'
import AdminPracticeList from 'pages/AdminPracticeList'
import AdminPracticeDetail from 'pages/AdminPracticeDetail'
import AdminNurseList from 'pages/AdminNurseList'
import AdminNurseDetail from 'pages/AdminNurseDetail'
import AdminPracticeProfile from 'pages/AdminPracticeDetail/Profile'
import AdminNurseProfile from 'pages/AdminNurseDetail/Profile'
import AdminNurseJobs from 'pages/AdminNurseDetail/Jobs'
import AdminPracticeJobs from 'pages/AdminPracticeDetail/Jobs'
import AdminNurseNearbyPractice from 'pages/AdminNurseDetail/NearbyPractices'
import AdminNurseDocumentList from 'pages/AdminNurseDetail/Documents'
import AdminNurseRightToWork from 'pages/AdminNurseDetail/RightToWork'
import AdminNurseStripeStatus from 'pages/AdminNurseDetail/StripeStatus'
import AdminNurseReviews from 'pages/AdminNurseDetail/Reviews'
import AdminNurseCancellations from 'pages/AdminNurseDetail/Cancellations'
import AdminNurseBlacklisted from 'pages/AdminNurseDetail/Blacklisted'
import AdminPracticeMatches from 'pages/AdminPracticeDetail/Matches'
import AdminPracticeReviews from 'pages/AdminPracticeDetail/Reviews'
import AdminPracticeCancellations from 'pages/AdminPracticeDetail/Cancellations'
import AdminPracticeBlacklisted from 'pages/AdminPracticeDetail/Blacklisted'
import AdminPracticeNearbyNurses from 'pages/AdminPracticeDetail/NearbyNurses'
import AdminJobList from 'pages/AdminJobList'
import AdminEmploymentList from 'pages/AdminEmploymentList'
import AdminEmploymentDetail from 'pages/AdminEmploymentDetail'
import AdminJobDetail from 'pages/AdminJobDetail'
import AdminJobProfile from 'pages/AdminJobDetail/Profile'
import AdminJobAvailableNurses from 'pages/AdminJobDetail/AvailableNurses'
import AdminJobMatches from 'pages/AdminJobDetail/Matches'
import AdminEmploymentPeople from 'pages/AdminEmploymentDetail/People'
import AdminEmploymentHours from 'pages/AdminEmploymentDetail/Hours'
import AdminEmploymentStatus from 'pages/AdminEmploymentDetail/Status'
import AdminEmploymentPayments from 'pages/AdminEmploymentDetail/Payments'
import PracticeGroupPaymentHistory from 'pages/PracticeGroupPaymentHistory'
import AdminPracticeGroupList from 'pages/AdminPracticeGroupList'
import AdminPracticeGroupDetail from 'pages/AdminPracticeGroupDetail'
import AdminPracticeGroupMembers from 'pages/AdminPracticeGroupDetail/Members'
import AdminPracticeGroupJobs from 'pages/AdminPracticeGroupDetail/Jobs'
import AdminPracticeGroupMatches from 'pages/AdminPracticeGroupDetail/Matches'
import AdminAffiliateList from 'pages/AdminAffiliateList'
import AdminAffiliateDetail from 'pages/AdminAffiliateDetail'
import AdminAffiliateProfile from 'pages/AdminAffiliateDetail/Profile'
import AdminAffiliatePracticeGroups from 'pages/AdminAffiliateDetail/PracticeGroups'
import AdminAffiliatePractices from 'pages/AdminAffiliateDetail/Practices'
import AdminAffiliateTransactions from 'pages/AdminAffiliateDetail/Transactions'
import NurseWaitlistCPD from 'pages/NurseWaitlist/CPD'
import NurseWaitlistCPDRedirect from 'pages/NurseWaitlist/CPDRedirect'
import NurseLocationReport from 'pages/AdminReports/NurseLocationReport'
import PracticeReport from 'pages/AdminReports/PracticeReport'
import AdminPracticeGroupPractices from 'pages/AdminPracticeGroupDetail/Practices'
import BillingAccountSetup from 'pages/PracticeBilling/BillingAccountSetup'
import AdminInvoiceList from 'pages/AdminInvoiceList'
import AdminInvoiceLateFeeList from 'pages/AdminInvoiceList/LateFeeList'
import PracticeGroupIssueInvoiceLateFee from 'pages/IssueInvoiceLateFee/PracticeGroup'
import PracticeIssueInvoiceLateFee from 'pages/IssueInvoiceLateFee/Practice'
import ChartsPage from 'pages/AdminReports/ChartsPage'
import AdminPracticeCharts from 'pages/AdminPracticeDetail/Charts'
import AdminPracticeGroupCharts from 'pages/AdminPracticeGroupDetail/Charts'
import AdminNurseCharts from 'pages/AdminNurseDetail/Charts'
import AdminUserList from 'pages/AdminUserList'
import AdminUserCreate from 'pages/AdminUserList/create'
import AdminUserUpdate from 'pages/AdminUserList/update'
import AdminPracticeMembers from 'pages/AdminPracticeDetail/Members'
import PracticeJobEmploymentList from 'pages/PracticeJobEmploymentList'
import PracticeGroupJobEmploymentList from 'pages/PracticeGroupJobEmploymentList'
import AdminPracticeNurseMap from 'pages/AdminPracticeDetail/NurseMap'
import AdminBillingAccountDetail from 'pages/AdminBillingAccountDetail'
import PracticeJobList from 'pages/PracticeJobs/JobList'
import NurseEmploymentList from 'pages/NurseJobs/EmploymentList'
import AdminNurseDocumentVerify from 'pages/AdminNurseDetail/Documents/DocumentVerify'
import AdminNurseGDCVerify from 'pages/AdminNurseDetail/Documents/GDCVerify'


const PublicRoute: React.FC = ({ children }) => {
  return <Layout children={children} showBottomNav={false} />
}

const PracticeRoute: React.FC = ({ children }) => {
  return (
    <IsPractice>
      <PracticeProvider>
        <Layout children={children} />
      </PracticeProvider>
    </IsPractice>
  )
}

const NurseRoute: React.FC = ({ children }) => {
  return (
    <IsNurse>
      <NurseProvider>
        <Layout children={children} />
      </NurseProvider>
    </IsNurse>
  )
}

const AdminRoute: React.FC = ({ children }) => {
  return (
    <IsAdmin>
      <AdminProvider>
        <Layout children={children} showBottomNav={false} mainContentPaddingX={1} />
      </AdminProvider>
    </IsAdmin>
  )
}

const PracticeGroupRoute: React.FC = ({ children }) => {
  return (
    <IsPracticeGroup>
      <PracticeGroupProvider>
        <Layout children={children} showBottomNav={false} mainContentPaddingX={1} />
      </PracticeGroupProvider>
    </IsPracticeGroup>
  )
}

const AffiliateRoute: React.FC = ({ children }) => {
  return (
    <IsAffiliate>
      <AffiliateProvider>
        <Layout children={children} showBottomNav={false} />
      </AffiliateProvider>
    </IsAffiliate>
  )
}

const JobRoute: React.FC = ({ children }) => {
  return (
    <JobProvider>
      {children}
    </JobProvider>
  )
}

const JobEmploymentRoute: React.FC = ({ children }) => {
  return (
    <JobEmploymentProvider>
      {children}
    </JobEmploymentProvider>
  )
}

const RootRedirect: React.FC = () => {
  const { authUser } = useAuthUser()

  if (authUser === undefined) {
    return null
  } else if (authUser?.isPractice) {
    return <Navigate replace to={practiceJobsURL(authUser.practiceIds?.[0] ?? 0)} />
  } else if (authUser?.isNurse) {
    return <Navigate replace to={nurseJobsURL(authUser.nurseId ?? 0)} />
  } else if (authUser?.isStaff) {
    return <Navigate replace to={adminPracticeListURL()} />
  } else {
    return <Navigate replace to={loginURL()} />
  }
}

const MeRedirect: React.FC = () => {
  const { authUser } = useAuthUser()
  const location = useLocation()
  if (!authUser) {
    return <Navigate replace to={loginURL(location.pathname)} />
  } else if (authUser.isNurse) {
    const url = location.pathname.replace('/me', `/nurse/${authUser.nurseId}`)
    return <Navigate replace to={url} />
  } else if (authUser.isPractice) {
    const url = location.pathname.replace('/me', `/practice/${authUser.practiceIds?.[0]}`)
    return <Navigate replace to={url} />
  }
  return <Navigate replace to={loginURL()} />
}

const MainRouter: React.FC = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route path='/public/login' element={<PublicRoute children={<Login />} />} />
      <Route path='/public/signup/practice' element={<PublicRoute children={<PracticeSignup />} />}>
        <Route index={true} element={<PracticeSignupJobList />} />
        <Route path='add' element={<PracticeSignupJobAdd />} />
        <Route path='edit/:jobId' element={<PracticeSignupJobEdit />} />
      </Route>
      <Route path='/public/signup/nurse' element={<PublicRoute children={<NurseSignup />} />} />
      <Route path='/public/reset-password' element={<PublicRoute children={<ResetPassword />} />} />
      <Route path='/public/invite/:code' element={<PublicRoute children={<PracticeInvitedSignup />} />} />
      <Route path='/public/join-cpd-waiting-list' element={<NurseWaitlistCPDRedirect />} />
      {/* affiliate signup routes */}
      <Route path='/signup-with-:affiliateCode' element={<PublicRoute children={<PracticeSignup />} />} >
        <Route index={true} element={<PracticeSignupJobList />} />
        <Route path='add' element={<PracticeSignupJobAdd />} />
        <Route path='edit/:jobId' element={<PracticeSignupJobEdit />} />
      </Route>
      {/* practice routes */}
      <Route path='/practice/:practiceId/jobs' element={<PracticeRoute children={<PracticeJobs />} />}>
        <Route path='editjoboptions' element={<PracticeEditDefaultJobOptions />} />
        <Route path='add' element={<PracticeAddJob />} />
        <Route path='list/:date' element={<PracticeJobList />} />
        <Route path=':jobId' element={<JobRoute children={<Outlet />} />} >
          <Route index={true} element={<PracticeViewJob />} />
          <Route path='edit' element={<PracticeEditJob />} />
          <Route path='cancel' element={<PracticeCancelJob />} />
        </Route>
        <Route path='employments/:jeId' element={<JobEmploymentRoute children={<Outlet />} />} >
          <Route path='timesheet' element={<TimeSheetView />} />
          <Route path='timesheet-change-propose' element={<TimeSheetProposeChange />} />
          <Route path='timesheet-change-edit/:tcId' element={<TimeSheetEditChange />} />
          <Route path='review-nurse' element={<ReviewNurse />} />
          <Route path='review-documents/:nurseId' element={<ReviewDocuments />} />
        </Route>
      </Route>
      <Route path='/practice/:practiceId/profile/edit' element={<PracticeRoute children={<PracticeProfileEdit />} />} />
      <Route path='/practice/:practiceId/members' element={<PracticeRoute children={<PracticeMemberList />} />} />
      <Route path='/practice/:practiceId/savings' element={<PracticeRoute children={<PracticeSavings />} />} />
      <Route path='/practice/:practiceId/billing' element={<PracticeRoute children={<PracticeBilling />} />} />
      <Route path='/practice/:practiceId/blacklist' element={<PracticeRoute children={<PracticeBlacklist />} />} />
      <Route path='/practice/:practiceId/setup-billing-account' element={<PracticeRoute children={<BillingAccountSetup />} />} />
      <Route path='/practice/:practiceId/notifications' element={<PracticeRoute children={<PracticeNotificationList />} />} />
      <Route path='/practice/:practiceId/welcome' element={<PracticeRoute children={<PrelaunchPage />} />} />
      <Route path='/practice/:practiceId/contracts' element={<PracticeRoute children={<PracticeContractAgreement />} />} />
      <Route path='/practice/:practiceId/issue-invoice-late-fee' element={<PracticeRoute children={<PracticeIssueInvoiceLateFee />} />} />
      <Route path='/practice/:practiceId/timesheets' element={<PracticeRoute children={<PracticeJobEmploymentList />} />} />
      {/* nurse routes */}
      <Route path='/nurse/:nurseId/jobs' element={<NurseRoute children={<NurseJobs />} />} >
        <Route path=':date' element={<NurseJobList />} />
        <Route path='take/:jobId' element={<JobRoute children={<NurseTakeJob />} />} />
        <Route path='employments/:jeId' element={<JobEmploymentRoute children={<Outlet />} />} >
          <Route path='view' element={<NurseViewJobEmployment />} />
          <Route path='cancel' element={<NurseCancelJobEmployment />} />
          <Route path='timesheet' element={<TimeSheetView />} />
          <Route path='timesheet-change-propose' element={<TimeSheetProposeChange />} />
          <Route path='timesheet-change-edit/:tcId' element={<TimeSheetEditChange />} />
        </Route>
        <Route path='employments-per-date/:date' element={<NurseEmploymentList />} />
      </Route>
      <Route path='/nurse/:nurseId/achievements' element={<NurseRoute children={<NurseAchievements />} />} />
      <Route path='/nurse/:nurseId/profile' element={<NurseRoute children={<NurseProfile />} />} />
      <Route path='/nurse/:nurseId/profile/edit' element={<NurseRoute children={<NurseProfileEdit />} />} />
      <Route path='/nurse/:nurseId/notification-settings' element={<NurseRoute children={<NurseNotificationSettings />} />} />
      <Route path='/nurse/:nurseId/billing' element={<NurseRoute children={<NurseBilling />} />} />
      <Route path='/nurse/:nurseId/notifications' element={<NurseRoute children={<NurseNotificationList />} />} />
      <Route path='/nurse/:nurseId/welcome' element={<NurseRoute children={<PrelaunchPage />} />} />
      <Route path='/nurse/:nurseId/contracts' element={<NurseRoute children={<NurseContractAgreement />} />} />
      <Route path='/nurse/:nurseId/affiliate/:affiliateId' element={<NurseRoute children={<NurseAffiliatePage />} />} />
      <Route path='/nurse/:nurseId/affiliate-signup' element={<NurseRoute children={<NurseAffiliateSignup />} />} />
      <Route path='/nurse/:nurseId/documents' element={<NurseRoute children={<NurseDocumentsPage />} />} />
      <Route path='/nurse/:nurseId/suspension' element={<NurseRoute children={<NurseSuspensionPage />} />} />
      <Route path='/nurse/:nurseId/waitlist-cpd' element={<NurseRoute children={<NurseWaitlistCPD />} />} />
      {/* admin routes */}
      <Route path='/admin' element={<AdminRoute children={<Outlet />} />}>
        <Route path='users' element={<AdminUserList />} />
        <Route path='users/create' element={<AdminUserCreate />} />
        <Route path='users/:userId' element={<AdminUserUpdate />} />
        <Route path='practices' element={<AdminPracticeList />} />
        <Route path='practices/:practiceId' element={<AdminPracticeDetail />}>
          <Route index={true} element={<AdminPracticeProfile />} />
          <Route path='profile' element={<AdminPracticeProfile />} />
          <Route path='jobs' element={<AdminPracticeJobs />} />
          <Route path='matches' element={<AdminPracticeMatches />} />
          <Route path='reviews' element={<AdminPracticeReviews />} />
          <Route path='cancellations' element={<AdminPracticeCancellations />} />
          <Route path='blacklist' element={<AdminPracticeBlacklisted />} />
          <Route path='nurses' element={<AdminPracticeNearbyNurses />} />
          <Route path='members' element={<AdminPracticeMembers />} />
          <Route path='charts' element={<AdminPracticeCharts />} />
          <Route path='nurse-map' element={<AdminPracticeNurseMap />} />
        </Route>
        <Route path='nurses' element={<AdminNurseList />} />
        <Route path='nurses/:nurseId' element={<AdminNurseDetail />}>
          <Route index={true} element={<AdminNurseProfile />} />
          <Route path='profile' element={<AdminNurseProfile />} />
          <Route path='documents' element={<AdminNurseDocumentList />}>
            <Route path='gdc' element={<AdminNurseGDCVerify />} />
            <Route path=':docType' element={<AdminNurseDocumentVerify />} />
          </Route>
          <Route path='rtw' element={<AdminNurseRightToWork />} />
          <Route path='stripe' element={<AdminNurseStripeStatus />} />
          <Route path='jobs' element={<AdminNurseJobs />} />
          <Route path='reviews' element={<AdminNurseReviews />} />
          <Route path='cancellations' element={<AdminNurseCancellations />} />
          <Route path='blacklist' element={<AdminNurseBlacklisted />} />
          <Route path='practices' element={<AdminNurseNearbyPractice />} />
          <Route path='charts' element={<AdminNurseCharts />} />
        </Route>
        <Route path='jobs' element={<AdminJobList />} />
        <Route path='jobs/:jobId' element={<AdminJobDetail />}>
          <Route index={true} element={<AdminJobProfile />} />
          <Route path='profile' element={<AdminJobProfile />} />
          <Route path='nurses' element={<AdminJobAvailableNurses />} />
          <Route path='matches' element={<AdminJobMatches />} />
        </Route>
        <Route path='employments' element={<AdminEmploymentList />} />
        <Route path='employments/:employmentId' element={<AdminEmploymentDetail />}>
          <Route index={true} element={<AdminEmploymentPeople />} />
          <Route path='people' element={<AdminEmploymentPeople />} />
          <Route path='hours' element={<AdminEmploymentHours />} />
          <Route path='status' element={<AdminEmploymentStatus />} />
          <Route path='payments' element={<AdminEmploymentPayments />} />
        </Route>
        <Route path='practice-groups' element={<AdminPracticeGroupList />} />
        <Route path='practice-groups/:practiceGroupId' element={<AdminPracticeGroupDetail />}>
          <Route index={true} element={<AdminPracticeGroupMembers />} />
          <Route path='members' element={<AdminPracticeGroupMembers />} />
          <Route path='jobs' element={<AdminPracticeGroupJobs />} />
          <Route path='matches' element={<AdminPracticeGroupMatches />} />
          <Route path='practices' element={<AdminPracticeGroupPractices />} />
          <Route path='charts' element={<AdminPracticeGroupCharts />} />
        </Route>
        <Route path='affiliates' element={<AdminAffiliateList />} />
        <Route path='affiliates/:affiliateId' element={<AdminAffiliateDetail />}>
          <Route index={true} element={<AdminAffiliateProfile />} />
          <Route path='profile' element={<AdminAffiliateProfile />} />
          <Route path='practice-groups' element={<AdminAffiliatePracticeGroups />} />
          <Route path='practices' element={<AdminAffiliatePractices />} />
          <Route path='transactions' element={<AdminAffiliateTransactions />} />
        </Route>
        <Route path='reports/nurse-location' element={<NurseLocationReport />} />
        <Route path='reports/practice' element={<PracticeReport />} />
        <Route path='reports/charts' element={<ChartsPage />} />
        <Route path='invoices' element={<AdminInvoiceList />} />
        <Route path='invoice-late-fees/:invoiceId' element={<AdminInvoiceLateFeeList />} />
        <Route path='billing-accounts/:billingAccountId' element={<AdminBillingAccountDetail />} />
      </Route>
      {/* practice group routes */}
      <Route path='/practice-group/:practiceGroupId/practices' element={<PracticeGroupRoute children={<PracticeGroupPracticeList />} />} />
      <Route path='/practice-group/:practiceGroupId/practices/add' element={<PracticeGroupRoute children={<PracticeGroupAddPractice />} />} />
      <Route path='/practice-group/:practiceGroupId/billing-accounts' element={<PracticeGroupRoute children={<PracticeGroupBillingAccountList />} />} />
      <Route path='/practice-group/:practiceGroupId/billing-accounts/add' element={<PracticeGroupRoute children={<PracticeGroupBillingAccountAdd />} />} />
      <Route path='/practice-group/:practiceGroupId/billing-accounts/edit/:billingAccountId' element={<PracticeGroupRoute children={<PracticeGroupBillingAccountEdit />} />} />
      <Route path='/practice-group/:practiceGroupId/payments' element={<PracticeGroupRoute children={<PracticeGroupPaymentHistory />} />} />
      <Route path='/practice-group/:practiceGroupId/issue-invoice-late-fee' element={<PracticeGroupRoute children={<PracticeGroupIssueInvoiceLateFee />} />} />
      <Route path='/practice-group/:practiceGroupId/timesheets' element={<PracticeGroupRoute children={<PracticeGroupJobEmploymentList />} />} />
      {/* affiliate routes */}
      <Route path='/affiliate/:affiliateId/transactions' element={<AffiliateRoute children={<AffiliateTransactionList />} />} />
      <Route path='/affiliate/:affiliateId/stripe-setup' element={<AffiliateRoute children={<AffiliateStripeSetup />} />} />
      {/* me redirect */}
      <Route path='/me/*' element={<MeRedirect />} />
      {/* root redirect */}
      <Route path='/' element={<RootRedirect />} />
    </Routes>
  )
}

export default MainRouter