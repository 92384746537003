import { Type, Transform, Expose } from 'class-transformer'
import { JobEmploymentFulfillmentStatus, JobEmploymentPaymentStatus, JobEmploymentTimesheetStatus } from 'types/types'
import { DateTime } from 'luxon'
import { nurseEmploymentListURL, nurseJobListURL, nurseViewJobEmploymentURL } from 'routes/urls'
import { chain } from 'lodash'

interface NurseDayJob {
  id: number
  practice_name: string
}

interface NurseDayEmployment {
  je_id: number
  fulfillment_status: JobEmploymentFulfillmentStatus
  payment_status: JobEmploymentPaymentStatus
  timesheet_status: JobEmploymentTimesheetStatus
}

export class NurseDay {
  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  date!: DateTime
  weeknum!: number
  weekdaynum!: number
  jobs: NurseDayJob[] = []
  employments: NurseDayEmployment[] = []

  @Expose()
  get key() {
    return `${this.weeknum}-${this.weekdaynum}`
  }

  @Expose()
  get dayOfMonth() {
    return this.date.isValid ? this.date.day : null
  }

  @Expose()
  get isPast() {
    const today = DateTime.utc().toFormat('yyyy-LL-dd')
    const date = this.date.toFormat('yyyy-LL-dd')
    return date < today
  }

  @Expose()
  get isToday() {
    const today = DateTime.utc().toFormat('yyyy-LL-dd')
    const date = this.date.toFormat('yyyy-LL-dd')
    return date === today
  }

  @Expose()
  get isFuture() {
    const today = DateTime.utc().toFormat('yyyy-LL-dd')
    const date = this.date.toFormat('yyyy-LL-dd')
    return date > today
  }

  get has_employments() {
    return this.employments.length > 0
  }

  get has_jobs() {
    return this.jobs.length > 0
  }

  clickURL(nurseId: number) {
    if (this.employments.length === 1) {
      return nurseViewJobEmploymentURL(nurseId, this.employments[0].je_id)
    } else if (this.employments.length > 1) {
      return nurseEmploymentListURL(nurseId, this.date.toFormat('yyyy-LL-dd'))
    } else if (this.has_jobs) {
      return nurseJobListURL(nurseId, this.date.toFormat('yyyy-LL-dd'))
    } else {
      return null
    }
  }

  get payment_error() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.payment_status)
      .some(s => ['practice_payment_error', 'practice_invoice_void', 'practice_invoice_payment_failed'].includes(s))
      .value()
    )
  }

  get payment_pending() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.payment_status)
      .some(s => ['practice_payment_pending', 'practice_payment_requested', 'practice_invoice_finalized'].includes(s))
      .value()
    )
  }

  get payment_success() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.payment_status)
      .every(s => ['practice_paid', 'practice_invoice_paid'].includes(s))
      .value()
    )
  }

  get timesheet_pending_approval() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.timesheet_status)
      .some(s => ['timesheet_pending_nurse_approval', 'timesheet_pending_practice_approval'].includes(s))
      .value()
    )
  }

  get cancelled() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.fulfillment_status)
      .every(s => s === 'cancelled')
      .value()
    )
  }

  get in_progress() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.fulfillment_status)
      .some(s => s === 'in_progress')
      .value()
    )
  }

  get scheduled() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.fulfillment_status)
      .some(s => s === 'scheduled')
      .value()
    )
  }

  get completed() {
    return this.has_employments && (
      chain(this.employments)
      .map(e => e.fulfillment_status)
      .every(s => s === 'completed')
      .value()
    )
  }

  @Expose()
  get backgroundColor() {
    if (!this.date.isValid) {
      return 'primary.light'
    } else if (this.has_employments) {
      if (this.payment_error || this.timesheet_pending_approval) {
        return 'error.light'
      } else if (this.cancelled) {
        if (!this.isPast && this.has_jobs) {
          return 'demand.mid'
        }
        return 'error.light'
      } else if (this.in_progress || this.payment_pending) {
        return 'warning.light'
      } else if (this.scheduled || this.completed || this.payment_success) {
        return 'success.light'
      } else {
        return 'background.default'
      }
    } else {
      if (this.isPast) {
        return 'background.paper'
      } else {
        if (!this.has_jobs) {
          // no-demand
          return 'background.default'
        } else {
          return 'demand.mid'
        }
      }
    }
  }

  @Expose()
  get label() {
    if (this.employments.length > 0) {
      if (this.timesheet_pending_approval) {
        return 'timesheet changed'
      } else if (this.payment_error) {
        return 'payment error'
      } else if (this.cancelled) {
        if (this.has_jobs && !this.isPast) {
          return 'Nurse Needed'
        }
        return 'cancelled'
      } else if (this.in_progress) {
        return 'in progress'
      } else if (this.payment_pending) {
        return 'awaiting payment'
      } else if (this.scheduled) {
        return 'booked'
      } else if (this.payment_success) {
        return 'paid'
      } else if (this.completed) {
        return 'awaiting payment'
      } 
    } else if (this.has_jobs && !this.isPast) {
      return 'Nurse Needed'
    }
    return ''
  }
}