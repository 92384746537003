import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import update from 'immutability-helper';
import { useNavigate } from 'react-router'
import { JobCancelForm } from 'types/interfaces'
import { Alert, Button, IconButton, Paper, Typography } from '@mui/material'
import Header from 'components/Header'
import { CancelOutlined } from '@mui/icons-material'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import JobCancelError from 'errors/JobCancelError';
import { useJob } from 'contexts/JobProvider';
import { practiceViewJobURL } from 'routes/urls';


const PracticeCancelJob: React.FC = () => {
  const [form, setForm] = React.useState<JobCancelForm>({})
  const [error, setError] = React.useState<JobCancelError>()
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()
  const navigate = useNavigate()
  const { job, reloadJob } = useJob()
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function submit() {
    if (!job) return
    try {
      setLoading(true)
      await api.cancelJob(job.job_id, form)
      await reloadJob()
      showAlert('success', 'Job is cancelled.')
      navigate(practiceViewJobURL(job.practice_id, job.job_id))
    } catch (e: any) {
      if (e instanceof JobCancelError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  function goBack() {
    if (!job) return
    navigate(practiceViewJobURL(job.practice_id, job.job_id))
  }

  const hasLateFees = job && (job.starting_soon_employment_count > 0)

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1}}>
      <Header variant='h2' text='Cancel Job'>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <FormField
        name='reason'
        label='Cancel Reason'
        multiline
        rows={4}
        onChange={(e) => updateForm('reason', e.target.value)}
        value={form.reason}
        errors={error?.reason}
      />
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      { hasLateFees ? (
        <Alert severity='error' variant='filled'>
          <Typography>
          Please note, if you cancel this job now you will be charged a late cancellation fee. <br/>
          This is because the job will be starting soon.  <br/>
          It's important that our nurses and practices can rely on each other. <br/>
          We charge 100% if the job is starting in less than 24 hours. <br/>
          We charge 50% if the job is starting between 24-72 hours. <br/>
          </Typography>
        </Alert>
      ) : null }
      <Button variant="contained" color="primary" onClick={submit} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Cancel Job'}
      </Button>
    </Paper>
  )
}

export default PracticeCancelJob