import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { NurseDay } from 'logic/NurseDay'
import React from 'react'
import { useNurse } from 'contexts/NurseProvider'
import { useNavigate } from 'react-router'
import useWidth from 'hooks/useWidth'

interface NurseCalendarDayProps {
  day: NurseDay
}

const NurseCalendarDay: React.FC<NurseCalendarDayProps> = ({ day }) => {
  const navigate = useNavigate()
  const { nurse } = useNurse()
  const { ref, width } = useWidth()
  const clickURL = nurse ? day.clickURL(nurse.nurse_id) : null

  function onClick() {
    if (clickURL !== null) navigate(clickURL)
  }

  return (
    <div onClick={onClick}>
      <Box ref={ref} sx={{
        textAlign: 'center',
        padding: 0,
        cursor: clickURL === null ? 'none' : 'pointer'
      }}>
        <Typography sx={{
          paddingTop: width ? width * 0.01 : '1px',
          fontSize: width ? width * 0.2 : '3em',
        }}>{day.label}</Typography>
      </Box>
    </div >
  )
}

export default NurseCalendarDay