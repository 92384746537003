import { User } from "logic/User"
import { buildURLSearchParams } from "logic/helpers"
import { 
  AdminAffiliateDetailTab, 
  AdminEmploymentDetailTab, 
  AdminJobDetailTab, 
  AdminNurseDetailTab, 
  AdminPracticeDetailTab, 
  AdminPracticeGroupDetailTab, 
  DocumentType
} from "types/types"

export function homeURL(user: User) {
  if (user.isStaff) {
    return adminPracticeListURL()
  } else if (user.affiliateId) {
    return affiliateTransactionListURL(user.affiliateId)
  } else if (user.practiceGroupIds.length > 0) {
    return practiceGroupPracticeListURL(user.practiceGroupIds[0])
  } else if (user.nurseId) {
    return nurseJobsURL(user.nurseId)
  } else if (user.practiceIds.length > 0) {
    return practiceJobsURL(user.practiceIds[0])
  } else {
    return ''
  }
}

export function loginURL(next?: string) {
  return next ? `/public/login?next=${next}` : '/public/login'
}

export function resetPasswordURL() {
  return '/public/reset-password'
}

export function practiceSignupURL() {
  return '/public/signup/practice'
}

export function practiceSignupAddJobURL() {
  return '/public/signup/practice/add'
}

export function practiceSignupEditJobURL(jobId: number) {
  return `/public/signup/practice/edit/${jobId}`
}

export function nurseSignupURL() {
  return '/public/signup/nurse'
}

export function practiceJobsURL(practiceId: number) {
  return `/practice/${practiceId}/jobs`
}

export function practiceJobListByDateURL(practiceId: number, date: string) {
  return `/practice/${practiceId}/jobs/list/${date}`
}

export function practiceViewJobURL(practiceId: number, jobId: number) {
  return `/practice/${practiceId}/jobs/${jobId}`
}

export function practiceAddJobURL(practiceId: number, date: string) {
  return `/practice/${practiceId}/jobs/add?date=${date}`
}

export function practiceEditJobURL(practiceId: number, jobId: number) {
  return `/practice/${practiceId}/jobs/${jobId}/edit`
}

export function practiceEditDefaultJobOptionsURL(practiceId: number) {
  return `/practice/${practiceId}/jobs/editjoboptions`
}

export function practiceBillingURL(practiceId: number) {
  return `/practice/${practiceId}/billing`
}

export function nurseBillingURL(nurseId: number) {
  return `/nurse/${nurseId}/billing`
}

export function practiceBillingAccountSetupURL(practiceId: number) {
  return `/practice/${practiceId}/setup-billing-account`
}

export function nurseJobsURL(nurseId: number) {
  return `/nurse/${nurseId}/jobs`
}

export function nurseJobListURL(nurseId: number, date: string) {
  return `/nurse/${nurseId}/jobs/${date}`
}

export function nurseTakeJobURL(nurseId: number, jobId: number) {
  return `/nurse/${nurseId}/jobs/take/${jobId}`
}

export function nurseProfileURL(nurseId: number, scrollTo?: 'extended-compliance' | 'right-to-work') {
  if (scrollTo) {
    return `/nurse/${nurseId}/profile?scrollTo=${scrollTo}`
  } else {
    return `/nurse/${nurseId}/profile`
  }
}

export function nurseProfileEditURL(nurseId: number) {
  return `/nurse/${nurseId}/profile/edit`
}

export function practiceProfileEditURL(practiceId: number) {
  return `/practice/${practiceId}/profile/edit`
}

export function nurseNotificationURL(nurseId: number) {
  return `/nurse/${nurseId}/notifications`
}

export function practiceNotificationURL(practiceId: number) {
  return `/practice/${practiceId}/notifications`
}

export function nurseAchievementsURL(nurseId: number) {
  return `/nurse/${nurseId}/achievements`
}

export function practiceSavingsURL(practiceId: number) {
  return `/practice/${practiceId}/savings`
}

export function nurseWelcomeURL(nurseId: number) {
  return `/nurse/${nurseId}/welcome`
}

export function practiceWelcomeURL(practiceId: number) {
  return `/practice/${practiceId}/welcome`
}

export function nurseContractAgreementURL(nurseId: number) {
  return `/nurse/${nurseId}/contracts`
}

export function practiceContractAgreementURL(practiceId: number) {
  return `/practice/${practiceId}/contracts`
}

export function nurseViewJobEmploymentURL(nurseId: number, jeId: number) {
  return `/nurse/${nurseId}/jobs/employments/${jeId}/view`
}

export function nurseEmploymentListURL(nurseId: number, date: string) {
  return `/nurse/${nurseId}/jobs/employments-per-date/${date}`
}

export function nurseCancelJobEmploymentURL(nurseId: number, jeId: number) {
  return `/nurse/${nurseId}/jobs/employments/${jeId}/cancel`
}

export function nurseViewTimeSheetURL(nurseId: number, jeId: number) {
  return `/nurse/${nurseId}/jobs/employments/${jeId}/timesheet`
}

export function nurseProposeTimeSheetChangeURL(nurseId: number, jeId: number) {
  return `/nurse/${nurseId}/jobs/employments/${jeId}/timesheet-change-propose`
}

export function nurseEditTimeSheetChangeURL(nurseId: number, jeId: number, tcId: number) {
  return `/nurse/${nurseId}/jobs/employments/${jeId}/timesheet-change-edit/${tcId}`
}

export function practiceCancelJobURL(practiceId: number, jobId: number) {
  return `/practice/${practiceId}/jobs/${jobId}/cancel`
}

export function practiceViewTimeSheetURL(practiceId: number, jeId: number) {
  return `/practice/${practiceId}/jobs/employments/${jeId}/timesheet`
}

export function practiceProposeTimeSheetChangeURL(practiceId: number, jeId: number) {
  return `/practice/${practiceId}/jobs/employments/${jeId}/timesheet-change-propose`
}

export function practiceEditTimeSheetChangeURL(practiceId: number, jeId: number, tcId: number) {
  return `/practice/${practiceId}/jobs/employments/${jeId}/timesheet-change-edit/${tcId}`
}

export function reviewNurseURL(practiceId: number, jeId: number) {
  return `/practice/${practiceId}/jobs/employments/${jeId}/review-nurse`
}

export function practiceViewNurseDocumentsURL(practiceId: number, jeId: number, nurseId: number) {
  return `/practice/${practiceId}/jobs/employments/${jeId}/review-documents/${nurseId}`
}

export function nurseNotificationSettings(nurseId: number) {
  return `/nurse/${nurseId}/notification-settings`
}

export function nurseAffiliateSignupURL(nurseId: number) {
  return `/nurse/${nurseId}/affiliate-signup`
}

export function nurseAffiliatePageURL(nurseId: number, affiliateId: number) {
  return `/nurse/${nurseId}/affiliate/${affiliateId}`
}

export function practiceBlacklistURL(practiceId: number) {
  return `/practice/${practiceId}/blacklist`
}

export function nurseDocumentsPageURL(nurseId: number) {
  return `/nurse/${nurseId}/documents`
}

export function practiceMemberListURL(practiceId: number) {
  return `/practice/${practiceId}/members`
}

export function practiceGroupPracticeListURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/practices`
}

export function practiceGroupPracticeAddURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/practices/add`
}

export function practiceGroupBillingAccountListURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/billing-accounts`
}

export function practiceGroupBillingAccountAddURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/billing-accounts/add`
}

export function practiceGroupBillingAccountEditURL(practiceGroupId: number, billingAccountId: number) {
  return `/practice-group/${practiceGroupId}/billing-accounts/edit/${billingAccountId}`
}

export function practiceGroupPaymentsURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/payments`
}

export function affiliateTransactionListURL(affiliateId: number) {
  return `/affiliate/${affiliateId}/transactions`
}

export function affiliateStripeSetupURL(affiliateId:  number) {
  return `/affiliate/${affiliateId}/stripe-setup`
}

export function affiliateUniqueSignupLink(affiliateCode: string) {
  return `${window.location.protocol}//${window.location.host}/signup-with-${affiliateCode}`
}

export function nurseSuspensionPageURL(nurseId: number) {
  return `/nurse/${nurseId}/suspension`
}

export function nurseWaitlistCPDURL(nurseId: number) {
  return `/nurse/${nurseId}/waitlist-cpd`
}

/* Admin Links */
export function adminUserListURL() {
  return '/admin/users'
}

export function adminUserCreateURL(practiceId?: number) {
  const params = buildURLSearchParams({practice_id: practiceId})
  return `/admin/users/create?${params.toString()}`
}

export function adminUserUpdateURL(userId: number) {
  return `/admin/users/${userId}`
}

export function adminPracticeListURL() {
  return '/admin/practices'
}

export function adminPracticeDetailURL(practiceId: number, tab: AdminPracticeDetailTab = 'profile') {
  return `/admin/practices/${practiceId}/${tab}`
}

export function adminNurseListURL() {
  return '/admin/nurses'
}

export function adminNurseDetailURL(nurseId: number, tab: AdminNurseDetailTab = 'profile') {
  return `/admin/nurses/${nurseId}/${tab}`
}

export function adminNurseDocumentVerifyURL(nurseId: number, docType: DocumentType) {
  return `/admin/nurses/${nurseId}/documents/${docType}`
}

export function adminJobListURL() {
  return '/admin/jobs'
}

export function adminJobDetailURL(jobId: number, tab: AdminJobDetailTab = 'profile') {
  return `/admin/jobs/${jobId}/${tab}`
}

export function adminJobEmploymentListURL() {
  return '/admin/employments'
}

export function adminJobEmploymentDetailURL(employmentId: number, tab: AdminEmploymentDetailTab = 'people') {
  return `/admin/employments/${employmentId}/${tab}`
}

export function adminPracticeGroupListURL() {
  return '/admin/practice-groups'
}

export function adminPracticeGroupDetailURL(practiceGroupId: number, tab: AdminPracticeGroupDetailTab = 'members') {
  return `/admin/practice-groups/${practiceGroupId}/${tab}`
}

export function adminAffiliateListURL() {
  return '/admin/affiliates'
}

export function adminAffiliateDetailURL(affiliateId: number, tab: AdminAffiliateDetailTab = 'profile') {
  return `/admin/affiliates/${affiliateId}/${tab}`
}

export function adminNurseLocationReportURL() {
  return '/admin/reports/nurse-location'
}

export function adminPracticeReportURL() {
  return '/admin/reports/practice'
}

export function adminChartsURL() {
  return '/admin/reports/charts'
}

export function adminInvoiceListURL() {
  return '/admin/invoices'
}

export function adminInvoiceLateFeeListURL(invoiceId: number) {
  return `/admin/invoice-late-fees/${invoiceId}`
}

export function practiceIssueLateFeeURL(practiceId: number) {
  return `/practice/${practiceId}/issue-invoice-late-fee`
}

export function practiceGroupIssueLateFeeURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/issue-invoice-late-fee`
}

export function practiceTimesheetListURL(practiceId: number) {
  return `/practice/${practiceId}/timesheets`
}

export function practiceGroupTimesheetListURL(practiceGroupId: number) {
  return `/practice-group/${practiceGroupId}/timesheets`
}

export function adminBillingAccountDetailURL(billingAccountId: number) {
  return `/admin/billing-accounts/${billingAccountId}`
}