import React from 'react'
import { useFirebase } from 'contexts/FirebaseProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from 'components/Header'
import FormField from 'components/FormField'
import { 
  practiceSignupURL, 
  nurseSignupURL, 
  resetPasswordURL,
  practiceJobsURL, 
  nurseJobsURL, 
  adminPracticeListURL,
  practiceGroupPracticeListURL,
  affiliateTransactionListURL
} from 'routes/urls'
import ErrorAlert from 'components/ErrorAlert';
import { trim } from 'lodash';
import { User } from 'logic/User';

const Login: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { auth } = useFirebase();
  const { refreshUser, logout, authUser } = useAuthUser()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  const [email, setEmail] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [errors, setErrors] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  function redirect(user: User) {
    const next = searchParams.get('next')
    if (next) {
      navigate(next)
    } else if (user.isStaff) {
      navigate(adminPracticeListURL())
    } else if (user.isNurse && user.nurseId) {
      navigate(nurseJobsURL(user.nurseId))
    } else if (user.isPracticeGroup && user.practiceGroupIds) {
      navigate(practiceGroupPracticeListURL(user.practiceGroupIds[0]))
    } else if (user.isPractice && user.practiceIds) {
      navigate(practiceJobsURL(user.practiceIds[0]))
    } else if (user.isAffiliate && user.affiliateId) {
      navigate(affiliateTransactionListURL(user.affiliateId))
    } 
  }

  async function login() {
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, email, password)
      const user = await refreshUser()
      if (user) redirect(user)
    } catch (e: any) {
      setErrors([e.message])
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (authUser && !email && !password) {
      redirect(authUser)
    }
  }, [authUser])

  return (
    <Box sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>
      <Box sx={{ flex: '1 1 50%', padding: 2 }}>
        <Header text='Login' variant='h1'></Header>
        <Paper sx={{ marginTop: 1, padding: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormField name='email' label='Email' onChange={(e) => setEmail(trim(e.target.value))}></FormField>
          <FormField name='password' label='Password' type='password' onChange={(e) => setPassword(e.target.value)}></FormField>
          <ErrorAlert errors={errors}></ErrorAlert>
          <Button variant="contained" onClick={login} disabled={loading}>{loading ? 'Please Wait ...' : 'Login'}</Button>
          <Button variant="text" component={RouterLink} to={resetPasswordURL()}>Forgot your password?</Button>
        </Paper>
      </Box>
      <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} variant="middle" flexItem>Or</Divider>
      <Box sx={{ flex: '1 1 50%', padding: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Header text='Create New Account' variant='h1'></Header>
        <Button variant="contained" component={RouterLink} to={nurseSignupURL()}>Sign Up as a Nurse</Button>
        <Button variant="contained" component={RouterLink} to={practiceSignupURL()}>Sign Up as a Practice</Button>
        <Link target='_blank' href='https://app.termly.io/document/terms-of-use-for-website/b64f9d4e-bad6-4317-9e9a-7e9cdd0a4c96'>
          Terms and Conditions
        </Link>
        <Link target='_blank' href='https://app.termly.io/document/privacy-policy/760e4e60-620e-44c8-8ba6-11d9e641127d'>
          Privacy Policy
        </Link>
      </Box>
    </Box>
  )
}

export default Login