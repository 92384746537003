import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Alert
} from '@mui/material'
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

export interface CheckboxSelectOption {
  value: string | number
  label: string
  description: string
}

interface CheckboxSelectFieldProps {
  value?: string | number
  options: CheckboxSelectOption[]
  onChange: (value: string | number) => void
  errors?: string[]
}

const CheckboxSelectField: React.FC<CheckboxSelectFieldProps> = ({ value, options, onChange, errors }) => {
  return (
    <FormControl>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <FormControlLabel label={option.label} control={(
            <Checkbox 
              checked={value === option.value} 
              onChange={() => onChange(option.value)}
            />
          )} />
          <FormHelperText>{option.description}</FormHelperText>
        </React.Fragment>
      ))}
      {!isEmpty(errors) ? (
        <Alert severity='error'><ErrorList errors={errors} /></Alert>
      ) : null}
    </FormControl>
  )
}

export default CheckboxSelectField