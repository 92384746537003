import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  FormHelperText
} from '@mui/material'
import ErrorList from 'components/ErrorList'
import { isEmpty } from 'lodash'

type ErrorListProps = {
  label?: React.ReactNode
  errors?: string[]
}

type CheckboxFormFieldProps = CheckboxProps & ErrorListProps

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = (props) => {
  const errors = props.errors
  const label = props.label

  return (
    <FormControl>
      <FormControlLabel control={<Checkbox {...props} />} label={label} />
      {!isEmpty(errors) ? (
        <FormHelperText error>
          <ErrorList errors={errors} />
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}


export default CheckboxFormField