import { Alert, Link, Paper, TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Box, Button } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { chain, isEmpty } from 'lodash'
import AdditionalCharge from 'logic/AddditionalCharge'
import Refund from 'logic/Refund'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import React from 'react'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'

type FeeCorrection = Refund | AdditionalCharge

const AdminEmploymentPayments: React.FC = () => {
  const { employment } = React.useContext(AdminEmploymentDetailContext)
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [refunds, setRefunds] = React.useState<Refund[]>([])
  const [additionalCharges, setAdditionalCharges] = React.useState<AdditionalCharge[]>([])

  const feeCorrections = React.useMemo(() => {
    return (
      chain<FeeCorrection>(refunds)
        .concat(additionalCharges)
        .sortBy((correction) => correction.created_at.toISO())
        .value()
    )
  }, [additionalCharges, refunds])

  const fetchRefunds = React.useCallback(async () => {
    setRefunds(await api.listRefunds({ employment_id: employment.id }))
  }, [api, employment])

  const fetchAdditionalCharges = React.useCallback(async () => {
    setAdditionalCharges(await api.listAdditionalCharges({ employment_id: employment.id }))
  }, [api, employment])

  const deleteFeeCorrection = React.useCallback(async (correction: FeeCorrection) => {
    try {
      if (correction instanceof Refund) {
        await api.deleteRefund(correction.id)
        await fetchRefunds()
        showAlert('success', 'Refund successfully deleted')
      } else {
        await api.deleteAdditionalCharge(correction.id)
        await fetchAdditionalCharges()
        showAlert('success', 'Additional charge successfully deleted')
      }
    } catch (error) {
      console.error(error)
      showAlert('error', 'Failed to delete fee correction')
    }
  }, [api, fetchAdditionalCharges, fetchRefunds, showAlert])

  React.useEffect(() => {
    fetchRefunds()
  }, [fetchRefunds])

  React.useEffect(() => {
    fetchAdditionalCharges()
  }, [fetchAdditionalCharges])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Paper sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Header variant='h3' text='Fees' />
        <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
          label: 'Nurse Paygrade',
          value: employment.nurse_paygrade_label,
        }, {
          label: 'Billable Duration',
          value: employment.billable_duration_label,
        }, {
          label: 'Nurse Fees',
          value: employment.nurse_fees_label,
        }, {
          label: 'Locumloop Fees',
          value: employment.locumloop_fees_label,
        }, {
          label: 'Practice Payment Option',
          value: employment.payment_option,
        }, {
          label: 'Practice Payment Method',
          value: employment.payment_method_label,
        }, {
          label: 'Nurse Late Cancel Fee',
          value: employment.nurse_late_fee_charge_label,
          sx: { whiteSpace: 'pre-line' }
        }]} />
        {employment.pay_by_invoice ? (
          <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
            label: 'Invoice',
            value: employment.invoice_label || 'pending',
          }, {
            label: 'Invoice Payment URL',
            value: employment.invoice_hosted_url ? <Link href={employment.invoice_hosted_url} target='_blank'>View</Link> : 'pending',
          }, {
            label: 'Invoice PDF',
            value: employment.invoice_pdf ? <Link href={employment.invoice_pdf} target='_blank'>View</Link> : 'pending',
          }, {
            label: 'Invoice Item ID',
            value: employment.invoice_item_id || 'pending',
          }, {
            label: 'Invoice Transfer ID',
            value: employment.invoice_transfer_id || 'pending',
          }, {
            label: 'Invoice Status',
            value: employment.invoice_status || 'pending',
          }]} />
        ) : (
          <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
            label: 'Payment Intent ID',
            value: employment.practice_payment_id || 'pending',
          }, {
            label: 'Payment Intent Status',
            value: employment.practice_payment_status || 'pending',
          }]} />
        )}
        {employment.payment_status !== 'practice_paid' && employment.payment_status !== 'practice_invoice_paid' ? (
          <React.Fragment>
            {employment.payment_error_label ? (
              <Alert severity='error' variant='filled'>
                There is an error on {employment.payment_method_label} <br />
                Error: {employment.payment_error_label}
              </Alert>
            ) : null}
            {!employment.is_ready_to_process_payment ? (
              <Alert severity='error' variant='filled'>
                {employment.not_ready_to_process_payment_reason}
              </Alert>
            ) : null}
          </React.Fragment>
        ) : null}
      </Paper>
      {!isEmpty(feeCorrections) ? (
        <React.Fragment>
          <Header text='Fee Corrections' variant='h2' />
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount (Nurse)</TableCell>
                  <TableCell>Amount (Locumloop)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeCorrections.map((correction) => (
                  <TableRow key={correction.id}>
                    <TableCell data-label='Date'>
                      {correction.created_at_label()}
                    </TableCell>
                    <TableCell data-label='Type'>
                      {correction instanceof Refund ? 'Refund' : 'Additional Charge'}
                    </TableCell>
                    <TableCell data-label='Amount (Nurse)'>
                      {correction.nurse_fees_label}
                    </TableCell>
                    <TableCell data-label='Amount (Locumloop)'>
                      {correction.locumloop_fees_label}
                    </TableCell>
                    <TableCell data-label='Status'>
                      {correction.status_label}
                    </TableCell>
                    <TableCell data-label='Description' sx={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                    }}>
                      {correction.description}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {correction.can_delete ? (
                          <Button
                            size='small'
                            variant='outlined'
                            color='error'
                            onClick={() => deleteFeeCorrection(correction)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ) : null}
    </Box>
  )
}

export default AdminEmploymentPayments