import { chain } from "lodash"
import { PaymentOption } from "types/types"

export const practicePositions = [{
  value: 'Principal',
  label: 'Principal',
}, {
  value: 'Manager',
  label: 'Manager',
}, {
  value: 'Other',
  label: 'Other',
}]

// nurse signup steps
export const STEP_BASIC = 0
export const STEP_DOCUMENTS = 1
export const STEP_IDCHECK = 2
export const STEP_BILLING = 3
export const STEP_SUMMARY = 4

// practice signup steps
export const PRACTICE_STEP_BASIC = 0
export const PRACTICE_STEP_POST_JOBS = 1
export const PRACTICE_STEP_BILLING = 2
export const PRACTICE_STEP_COMPLETED = 3

export const DOCUMENTATIONS_LABEL_MAPPING = {
  'gdc': 'GDC Certificate',
  'indemnity_insurance': 'Indemnity Insurance',
  'hepatitis_b_vaccination': 'Hepatitis B Vaccination',
  'hepatitis_c': 'Hepatitis C Check (Scotland Only)',
  'tb': 'TB Check / Vaccination (Scotland Only)',
  'hiv': 'HIV Check (Scotland Only)',
  'dbs': 'DBS',
  'pvg': 'PVG',
  'infection_control': 'Infection Control',
  'cpr': 'CPR',
}
export const DOCUMENTATIONS_LABELS = chain(DOCUMENTATIONS_LABEL_MAPPING).values().value()

export const FILE_LABEL_MAPPING = {
  'gdc': 'GDC Certificate',
  'indemnity_insurance': 'Indemnity Insurance',
  'immunizations': 'Immunisations and Tests',
  'dbs': 'Enhanced DBS Check (England & Wales) / PVG Scheme (Scotland)',
  'infection_control': 'Infection Control (Optional)',
  'cpr': 'CPR (Optional)',
}
export const FILE_LABELS = chain(FILE_LABEL_MAPPING).values().value()

export const STRIPE_VERIFICATION_STATUS_MAPPING = {
  'individual.verification.additional_document': 'A document showing address, either a passport, local ID card, or utility bill from a well-known utility company.',
  'individual.verification.document': 'An identifying document, either a passport or local ID card.',
}

export const PAYMENT_OPTION_TO_PAYMENT_SCHEDULE_MAPPING = {
  'pay_eod_with_card': { 
    'practice': 'Autopay (card) end of day when job completes',
    'nurse': '1 to 7 days',
  },
  'pay_eod_with_bacs_debit': { 
    'practice': 'Autopay (direct debit) end of day when job completes',
    'nurse': '7 to 14 days',
  },
  'invoice_weekly_autopay_with_card': { 
    'practice': 'Autopay (card) end of week',
    'nurse': 'end of week + 1 to 7 days',
  },
  'invoice_weekly_autopay_with_bacs_debit': { 
    'practice': 'Autopay (direct debit) end of week',
    'nurse': 'end of week + 7 to 14 days',
  },
  'invoice_weekly_pay_manually': { 
    'practice': 'Send invoice at the end of the week. Payment due within 7 days',
    'nurse': 'end of week + 7 to 14 days',
  },
  'invoice_biweekly_autopay_with_card': { 
    'practice': 'Autopay (card) on the 1st and 15th of each month',
    'nurse': '1st/15th of each month + 1 to 7 days',
  },
  'invoice_biweekly_autopay_with_bacs_debit': { 
    'practice': 'Autopay (direct debit) on the 1st and 15th of each month',
    'nurse': '1st/15th of each month + 7 to 14 days',
  },
  'invoice_biweekly_pay_manually': { 
    'practice': 'Send invoice on the 1st and 15th of each month. Payment due within 7 days',
    'nurse': '1st/15th of each month + 7 to 14 days',
  },
  'invoice_monthly_autopay_with_card': { 
    'practice': 'Autopay (card) end of month',
    'nurse': 'end of month + 1 to 7 days',
  },
  'invoice_monthly_autopay_with_bacs_debit': { 
    'practice': 'Autopay (direct debit) end of month',
    'nurse': 'end of month + 7 to 14 days',
  },
  'invoice_monthly_pay_manually': { 
    'practice': 'Send invoice at the end of the month. Payment due within 7 days',
    'nurse': 'end of month + 7 to 14 days',
  },
}

export const PAYMENT_OPTIONS_FOR_STANDALONE_PRACTICES: PaymentOption[] = [
  'pay_eod_with_card',
  'invoice_weekly_pay_manually',
]

export const PAYMENT_OPTIONS_FOR_CORPORATE_PRACTICES: PaymentOption[] = [
  'pay_eod_with_card',
  'pay_eod_with_bacs_debit',
  'invoice_weekly_pay_manually',
  'invoice_biweekly_pay_manually',
  'invoice_monthly_pay_manually',
]