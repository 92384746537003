import JobEmployment from 'logic/JobEmployment'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { 
  adminPracticeDetailURL,
  adminNurseDetailURL,
} from 'routes/urls'
import { useAPI } from 'contexts/APIProvider'
import { Button, Chip, MenuItem, Tooltip, Typography } from '@mui/material'
import { JobEmploymentSearchForm } from 'types/interfaces'
import { chain } from 'lodash'
import MultiSelect from 'components/MultiSelect'
import FormField from 'components/FormField'
import ExportOnlyToolBar from 'components/ExportToolbar'
import { 
  DataGridPro, 
  GridColDef, 
  GridRenderCellParams, 
  GridValueGetterParams,
  GridColumnVisibilityModel,
  GridSortModel,
  GridEventListener,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { 
  Link,
  Alert,
  Box,
} from '@mui/material'
import { Observable } from 'rxjs';
import PracticeGroup from 'logic/PracticeGroup';
import { DocumentType } from 'types/types';

type FilterOptions = 
| 'cancel_by'
| 'fulfillment_statuses'
| 'payment_statuses'
| 'timesheet_statuses'
| 'from_date'
| 'to_date'
| 'rating'
| 'nurse'
| 'practice'
| 'practice_group'
| 'documents_expired_or_missing'

const all_filters: FilterOptions[] = [
  'cancel_by',
  'fulfillment_statuses',
  'payment_statuses',
  'timesheet_statuses',
  'from_date',
  'to_date',
  'rating',
  'nurse',
  'practice',
  'practice_group',
  'documents_expired_or_missing',
]

interface AdminJobEmploymentTableProps {
  exclude_filters?: FilterOptions[]
  reload$?: Observable<boolean>
  actions?: React.ReactNode
  onRowClick?: GridEventListener<"rowClick">
  form: JobEmploymentSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminJobEmploymentTable: React.FC<AdminJobEmploymentTableProps> = ({
  exclude_filters = [],
  reload$,
  onRowClick,
  actions,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [employments, setEmployments] = React.useState<JobEmployment[]>([])
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const filters = React.useMemo(() => {
    return all_filters.filter((filter) => !exclude_filters.includes(filter))
  }, [exclude_filters])

  const fetchJobEmployments = React.useCallback(async () => {
    setLoading(true)
    const results = chain(await api.listJobEmployments(form)).sortBy('countdown').value()
    setEmployments(results)
    setLoading(false)
  }, [api, form])

  const fetchPracticeGroups = React.useCallback(async () => {
    setPracticeGroups(await api.listPracticeGroup())
  }, [api])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  // React.useEffect(() => {
  //   fetchJobEmployments()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchJobEmployments())
    return () => sub?.unsubscribe()
  }, [fetchJobEmployments, reload$])

  React.useEffect(() => {
    fetchPracticeGroups()
  }, [fetchPracticeGroups])

  const columns: GridColDef<JobEmployment>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'id',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Status',
    field: 'fulfillment_status',
    sort: false,
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <Chip 
            size='small' 
            label={employment.fulfillment_status} 
            color={employment.fulfillment_status_color}/>
          {employment.payment_status ? (
            <Chip 
              size='small' 
              label={employment.payment_status} 
              color={employment.payment_status_color} />
          ) : null}
          {employment.timesheet_status ? (
            <Chip
              size='small'
              label={employment.timesheet_status}
              color={employment.timesheet_status_color}/>
          ) : null}
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Documents (out of date)',
    field: 'documents_expired',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.documents_expired.length
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {employment.documents_expired.map((doc) => (
            <Chip key={doc} size='small' label={doc} color='error'/>
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Documents (missing date)',
    field: 'documents_missing_date',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.documents_missing_date.length
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {employment.documents_missing_date.map((doc) => (
            <Chip key={doc} size='small' label={doc} color='error'/>
          ))}
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Practice Group',
    field: 'practice_group_name',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.practice_group_name ?? 'Standalone'
    },
  }, {
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link typography='body1' component={RouterLink} to={adminPracticeDetailURL(employment.practice_id)}>
            {employment.practice_name}
          </Link>
          <Typography>{employment.practice_phone_number}</Typography>
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Nurse',
    field: 'nurse_name',
    type: 'string',
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link typography='body1' component={RouterLink} to={adminNurseDetailURL(employment.nurse_id)}>
            {employment.nurse_name}
          </Link>
          <Typography>{employment.nurse_phone_number}</Typography>
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Posted At',
    field: 'job_posted_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.job_posted_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.posted_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'Taken At',
    field: 'job_taken_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.job_taken_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.taken_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'Start At',
    field: 'start_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.start_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.start_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'End At',
    field: 'end_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.end_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      return <Typography>{employment.end_at_label('Europe/London')}</Typography>
    }
  }, {
    minWidth: 300,
    headerName: 'Cancel At',
    field: 'cancel_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.cancel_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      const countdown_label = employment.cancel_countdown_label
      if (!countdown_label) {
        return 'N/A'
      } else {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{employment.cancel_countdown_label} before job starts</Typography>
            <Typography>{employment.cancel_at_label('Europe/London')}</Typography>
          </Box>
        )
      }
    }
  }, {
    minWidth: 50,
    headerName: 'Cancel By',
    field: 'cancel_by',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Cancel Reason',
    field: 'cancel_reason',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: 'Rating',
    field: 'review_rating',
    type: 'number',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      const rating = params.row.review_rating
      if (rating === 1) {
        return 'Excellent'
      } else if (rating === 2) {
        return 'Good'
      } else if (rating === 3) {
        return 'Average'
      } else if (rating === 4) {
        return 'Poor'
      } else {
        return 'N/A'
      }
    },
  }, {
    minWidth: 200,
    headerName: 'Comments',
    field: 'review_notes',
    type: 'string',
  }, {
    minWidth: 100,
    headerName: '# Red Dots',
    field: 'nurse_num_cancellations',
    type: 'number'
  }, {
    minWidth: 200,
    headerName: 'Payment Option',
    field: 'is_ready_to_process_payment',
    type: 'boolean',
    renderCell: (params: GridRenderCellParams<JobEmployment>) => {
      const employment = params.row
      if (!employment.is_ready_to_process_payment) {
        return (
          <Tooltip title={employment.not_ready_to_process_payment_reason}>
            <Chip
              size='small'
              label='Not Ready to Process Payments!'
              color='error' />
          </Tooltip>
        )
      } else {
        return employment.payment_option ?? 'N/A'
      }
    }
  }, {
    minWidth: 150,
    headerName: 'Nurse ID Country',
    field: 'nurse_id_issuing_country'
  }, {
    minWidth: 200,
    headerName: 'Billable Hours',
    field: 'billable_duration',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<JobEmployment>) => {
      return params.row.billable_duration_label
    },
  }, {
    minWidth: 200,
    headerName: 'Payment Intent Status',
    field: 'practice_payment_status',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Invoice Status',
    field: 'invoice_status',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Payment Error',
    field: 'payment_error_label',
    type: 'string',
  }], [])

  const fulfillment_statuses = [
    'scheduled', 
    'in_progress', 
    'completed', 
    'cancelled'
  ]
  const payment_statuses = [
    'practice_paid',
    'practice_payment_error',
    'practice_payment_pending',
    'practice_payment_requested',
    'practice_invoice_finalized',
    'practice_invoice_paid',
    'practice_invoice_void',
    'practice_invoice_payment_failed',
  ]
  const timesheet_statuses = [
    'timesheet_pending_nurse_approval', 
    'timesheet_pending_practice_approval', 
    'timesheet_approved', 
    'timesheet_disapproved'
  ]
  const documents: DocumentType[] = [
    'gdc',
    'indemnity_insurance' ,
    'hepatitis_b_vaccination' ,
    'hepatitis_c',
    'tb',
    'hiv',
    'dbs',
    'pvg',
    'infection_control',
    'cpr',
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {filters.includes('nurse') ? (
          <FormField
            name='nurse'
            label='Nurse'
            sx={{ width: 150 }}
            onChange={(e) => updateForm('nurse', e.target.value)}
            value={form.nurse ?? ''}/>
        ) : null}
        {filters.includes('practice') ? (
          <FormField
            name='practice'
            label='Practice'
            sx={{ width: 150 }}
            onChange={(e) => updateForm('practice', e.target.value)}
            value={form.practice ?? ''}/>
        ) : null}
        {filters.includes('practice_group') ? (
          <FormField
            select
            name='practice_group'
            label='Practice Group'
            sx={{ width: 150 }}
            onChange={(e) => updateForm('practice_group_id', e.target.value)}
            value={form.practice_group_id ?? ''}>
            <MenuItem value=''>Not Specified</MenuItem>
            <MenuItem value='-999'>Standalone</MenuItem>
            {practiceGroups.map((group) => (
              <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
          </FormField>
        ): null}
        {filters.includes('fulfillment_statuses') ? (
          <MultiSelect
            name='fulfillment_statuses'
            label='Status'
            width={150}
            options={fulfillment_statuses}
            onChange={(e) => updateForm('fulfillment_statuses', e.target.value)}
            value={form.fulfillment_statuses ?? []}
          />
        ) : null}
        {filters.includes('payment_statuses') ? (
          <MultiSelect
            name='payment_statuses'
            label='Payment'
            width={150}
            options={payment_statuses}
            onChange={(e) => updateForm('payment_statuses', e.target.value)}
            value={form.payment_statuses ?? []}
          />
        ) : null}
        {filters.includes('timesheet_statuses') ? (
          <MultiSelect
            name='timesheet_statuses'
            label='Timesheet'
            width={150}
            options={timesheet_statuses}
            onChange={(e) => updateForm('timesheet_statuses', e.target.value)}
            value={form.timesheet_statuses ?? []}
          />
        ) : null}
        {filters.includes('cancel_by') ? (
          <FormField
            sx={{ width: 150 }}
            label='Cancel By'
            select
            value={form?.cancel_by}
            onChange={(e) => updateForm('cancel_by', e.target.value)}
          >
            <MenuItem value={'practice'}>Practice</MenuItem>
            <MenuItem value={'nurse'}>Nurse</MenuItem>
          </FormField>
        ) : null}
        {filters.includes('rating') ? (
          <FormField
            sx={{ width: 150 }}
            label='Rating'
            select
            value={form?.rating}
            onChange={(e) => updateForm('rating', e.target.value)}
          >
            <MenuItem value={1}>Excellent</MenuItem>
            <MenuItem value={2}>Good</MenuItem>
            <MenuItem value={3}>Average</MenuItem>
            <MenuItem value={4}>Poor</MenuItem>
          </FormField>
        ) : null}
        {filters.includes('documents_expired_or_missing') ? (
          <MultiSelect
            name='documents_expired_or_missing'
            label='Expired/Missing Documents'
            width={200}
            options={documents}
            onChange={(e) => updateForm('documents_expired_or_missing', e.target.value)}
            value={form.documents_expired_or_missing ?? []}
          />
        ) : null}
        {filters.includes('from_date') ? (
          <FormField
            type='date'
            name='from_date'
            label='Start Date'
            InputLabelProps={{ shrink: true }}
            onChange={(e) => updateForm('from_date', e.target.value)}
            value={form.from_date ?? ''}/>
        ) : null}
        {filters.includes('to_date') ? (
          <FormField
            type='date'
            name='to_date'
            label='End Date'
            InputLabelProps={{ shrink: true }}
            onChange={(e) => updateForm('to_date', e.target.value)}
            value={form.to_date ?? ''}/>
        ) : null}
        <Button
          variant="contained"
          onClick={fetchJobEmployments}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {isEmpty(employments) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{employments.length} records returned. {selection.length} selected</Alert>
          <DataGridPro 
            rows={employments}
            columns={columns}
            getRowId={(employment) => employment.id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
            slots={{ toolbar: ExportOnlyToolBar }}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminJobEmploymentTable