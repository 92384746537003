import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import NurseSignupContext from './context';
import Header from 'components/Header'
import { useNavigate } from 'react-router';
import { nurseJobsURL } from 'routes/urls'
import DocumentUpload from 'components/DocumentUpload';
import GDCRegistration from 'components/GDCRegistration';

const NurseSignupDocuments: React.FC = () => {
  const { toNextStep, toPreviousStep, nurse, emitGTMEvent, notification$ } = React.useContext(NurseSignupContext)
  const navigate = useNavigate()
  
  function onSkip() {
    if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
  }

  function onNext() {
    emitGTMEvent('nurse-signup-documents')
    toNextStep()
  }

  const canNext = React.useMemo(() => {
    if (!nurse) return false
    return !nurse.has_missing_documents
  }, [nurse])

  if (!nurse) return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Documents'></Header>
      <React.Fragment>
        <GDCRegistration nurseId={nurse.nurse_id} notification$={notification$} />
        <DocumentUpload nurseId={nurse.nurse_id} fileType='indemnity_insurance' />
        <DocumentUpload nurseId={nurse.nurse_id} fileType='immunizations' />
        <DocumentUpload nurseId={nurse.nurse_id} fileType='dbs' />
        <DocumentUpload nurseId={nurse.nurse_id} fileType='cpr' />
        <DocumentUpload nurseId={nurse.nurse_id} fileType='infection_control'/>
      </React.Fragment>
      {canNext ? (
        <Button variant="contained" color="primary" onClick={onNext}>Next</Button>
      ) : null}
      <Button variant="outlined" color="primary" onClick={toPreviousStep}>Back</Button>
      <Button variant="outlined" color="primary" onClick={onSkip}>Skip</Button>
    </Box>
  )
}

export default NurseSignupDocuments