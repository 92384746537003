import { DocumentFileType, DocumentType } from 'types/types'
import { forEach, forOwn, isArrayLikeObject, round } from "lodash";
import { Calendar } from 'calendar'
import { DateTime } from 'luxon'
import API from "./API";
import Nurse from "./Nurse";

export async function getStripeURL(api: API, nurse: Nurse) {
  switch (nurse.stripe_step) {
    case 'COMPLETED':
    case 'SUBMITTED':
      return await api.getStripeDashboardURL(nurse.nurse_id)
    case 'PROCESSING':
    case 'NOT_STARTED':
    default:
      return await api.getStripeConnectURL(nurse.nurse_id)
  }
}

export function fromKmToMiles(value: string | number, precision: number = 2) {
  if (value === '') return null
  return round(Number(value) * 0.621371, precision)
}

export function fromMilesToKm(value: string | number, precision: number = 2) {
  if (value === '') return null
  return round(Number(value) * 1.609, precision)
}

export function getEmptyCalendar(year: number, month: number) {
  const cal = new Calendar(1)
  const weeks = cal.monthDays(year, month - 1) // luxon January is 1, but JSDate January is 0
  const retval: { [key: string]: any } = {}
  weeks.forEach((week, weeknum) => {
    week.forEach((day, weekdaynum) => {
      const key = `${weeknum}-${weekdaynum}`
      const date = day ? DateTime.fromObject({ year, month, day }) : null
      retval[key] = {
        'date': date ? date.toFormat('yyyy-LL-dd') : '',
        'weeknum': weeknum,
        'weekdaynum': weekdaynum
      }
    })
  })
  return retval
}

export function downloadFile(blob: string) {
  const data = "data:text/csv;charset=utf-8," + blob
  const encodedUri = encodeURI(data)
  window.open(encodedUri)
}

export function buildURLSearchParams(data: any) {
  const params = new URLSearchParams()
  forOwn(data, (value, key) => {
    if (value === undefined || value === '') {
      return
    } else if (isArrayLikeObject(value)) {
      forEach(value, (item) => {
        params.append(key, item)
      })
    } else {
      params.append(key, value)
    }
  })
  return params
}

export function approvalBooleanToString(value?: boolean | null) {
  if (value === undefined || value === null) {
    return 'not-set'
  } else if (value) {
    return 'approved'
  } else {
    return 'disapproved'
  }
}

export function approvalStringToBoolean(value: string) {
  if (value === 'approved') {
    return true
  } else if (value === 'disapproved') {
    return false
  } else {
    return null
  }
}

export function fromCentsToPounds(value?: string | number) {
  if (value === undefined || value === '') return undefined
  return Number(value) / 100
}

export function fromPountsToCents(value?: string | number) {
  if (value === undefined || value === '') return undefined
  return Number(value) * 100
}

export function getDocumentTypesFromFileType(fileType: DocumentFileType): DocumentType[] {
  if (fileType === 'gdc') {
    return ['gdc']
  } else if (fileType === 'indemnity_insurance') {
    return ['indemnity_insurance']
  } else if (fileType === 'immunizations') {
    return ['hepatitis_b_vaccination', 'hepatitis_c', 'tb', 'hiv']
  } else if (fileType === 'dbs') {
    return ['dbs', 'pvg']
  } else if (fileType === 'infection_control') {
    return ['infection_control']
  } else if (fileType === 'cpr') {
    return ['cpr']
  }
  return []
}

export function getFileTypeFromDocumentType(docType: DocumentType): DocumentFileType {
  if (docType === 'gdc') {
    return 'gdc'
  } else if (docType === 'indemnity_insurance') {
    return 'indemnity_insurance'
  } else if (['hepatitis_b_vaccination', 'hepatitis_c', 'tb', 'hiv'].includes(docType)) {
    return 'immunizations'
  } else if (['dbs', 'pvg'].includes(docType)) {
    return 'dbs'
  } else if (docType === 'infection_control') {
    return 'infection_control'
  } else {
    return 'cpr'
  }
}