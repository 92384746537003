import { Box, Button } from '@mui/material'
import Header from 'components/Header'
import update from 'immutability-helper';
import { useAPI } from 'contexts/APIProvider'
import EditJobError from 'errors/EditJobError'
import React from 'react'
import { useNavigate } from 'react-router'
import { practiceSignupURL } from 'routes/urls'
import { EditJobForm, EstimateCost } from 'types/interfaces'
import { useParams } from 'react-router';
import Job from 'logic/Job';
import PracticeSignupContext from '../context';
import { Link } from 'react-router-dom';
import JobForm from 'components/JobForm';

const PracticeSignupJobEdit: React.FC = () => {
  const { practice, reloadJobs, paygradeStats } = React.useContext(PracticeSignupContext)
  const params = useParams()
  const { api } = useAPI()
  const navigate = useNavigate()
  const [job, setJob] = React.useState<Job>()
  const [form, setForm] = React.useState<EditJobForm>({})
  const [error, setError] = React.useState<EditJobError>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [estimatedCost, setEstimatedCost] = React.useState<EstimateCost | null>()

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  const jobId = React.useMemo(() => Number(params.jobId), [params.jobId])

  const fetchJob = React.useCallback(async () => {
    setJob(await api.getJob(jobId))
  }, [jobId, api])

  const fetchEstimatedCost = React.useCallback(async () => {
    if (job && form.start_time && form.end_time && form.lunch_break !== undefined && form.headcount) {
      setEstimatedCost(await api.getEstimatedCost(
        job.practice_id,
        {
          date: job.start_at.toISODate(),
          start_time: form.start_time,
          end_time: form.end_time,
          lunch_break: form.lunch_break,
          headcount: form.headcount
        }
      ))
    }
  }, [job, form.start_time, form.end_time, form.lunch_break, form.headcount, api])

  const save = React.useCallback(async () => {
    try {
      setLoading(true)
      await api.editJob(Number(params.jobId), form)
      await reloadJobs()
      navigate(practiceSignupURL())
    } catch (e) {
      if (e instanceof EditJobError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }, [api, form, navigate, params.jobId, reloadJobs])

  React.useEffect(() => {
    fetchJob()
  }, [fetchJob])

  React.useEffect(() => {
    fetchEstimatedCost()
  }, [fetchEstimatedCost])

  React.useEffect(() => {
    if (job === undefined) return
    const tz = job.practice_tz
    setForm((form) => update(form, {
      start_time: { $set: job.start_at.setZone(tz).toFormat('HH:mm') },
      end_time: { $set: job.end_at.setZone(tz).toFormat('HH:mm') },
      lunch_break: { $set: job.lunch_break.as('minute') },
      headcount: { $set: job.headcount },
      description: { $set: job.description }
    }))
  }, [job])

  return (
    <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text='Update Job Post'></Header>
      <JobForm
        form={form}
        error={error}
        updateForm={updateForm}
        mode='edit'
        paygradeStats={paygradeStats}
        estimatedCost={estimatedCost}
        practiceFeeLabel={practice?.locumloop_fees_label ?? '£20'}
      />
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Update Job'}
      </Button>
      <Button 
        variant="outlined" 
        color="primary" 
        component={Link}
        to={practiceSignupURL()}
      >
        Back
      </Button>
    </Box>
  )
}

export default PracticeSignupJobEdit