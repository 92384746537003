import React from 'react'
import Box from '@mui/material/Box'
import { Outlet, useMatch, useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Calendar from 'components/Calendar'
import CalendarDay from 'components/Calendar/Day'
import { switchMap, map, share, catchError } from 'rxjs'
import { docData } from 'rxfire/firestore';
import { doc } from "firebase/firestore";
import { NurseDay } from 'logic/NurseDay'
import useCalendar from 'hooks/useCalendar'
import { useFirebase } from 'contexts/FirebaseProvider'
import { useNurse } from 'contexts/NurseProvider'
import { chain, isEmpty } from 'lodash'
import { plainToClass } from 'class-transformer'
import { getEmptyCalendar } from 'logic/helpers'
import NurseCalendarDay from './CalendarDay'
import Loading from 'components/Loading'
import { Alert, Button, Paper, Typography } from '@mui/material'
import { nurseAffiliateSignupURL, nurseProfileURL, nurseSignupURL, nurseSuspensionPageURL, nurseNotificationSettings } from 'routes/urls'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { useAuthUser } from 'contexts/AuthUserProvider'

const NurseJobs: React.FC = () => {
  const navigate = useNavigate()
  const { showAlert } = useSnackBarAlert()
  const { nurse } = useNurse()
  const { authUser } = useAuthUser()
  const matchIndexPage = useMatch('/nurse/:nurseId/jobs')
  const { firestore } = useFirebase()
  const { interval, interval$, toNextMonth, toPreviousMonth, toCurrentMonth } = useCalendar(nurse?.tz)
  const [days, setDays] = React.useState<NurseDay[]>([])

  React.useEffect(() => {
    if (!nurse) {
      return
    }

    const source$ = interval$.pipe(
      switchMap(interval => {
        const path = `nurses/${nurse.firebase_id}/calendars/${interval.start.year}#${interval.start.month}`
        const ref = doc(firestore, path)
        return docData(ref).pipe(
          map(data => isEmpty(data) ? getEmptyCalendar(interval.start.year, interval.start.month) : data),
          catchError(async (e) => {
            console.error(e)
            return getEmptyCalendar(interval.start.year, interval.start.month)
          }),
        )
      }),
      map(data => (
        chain(data)
          .omit('permission')
          .values()
          .sortBy('weeknum', 'weekdaynum')
          .map(day => plainToClass(NurseDay, day))
          .value()
      )),
      share(),
    )

    const sub = source$.subscribe(setDays)
    return () => sub.unsubscribe()
  }, [nurse])

  const onJoinAffiliateProgramme = React.useCallback(() => {
    if (!nurse) return
    if (!nurse.can_join_affiliate_programme) {
      const alertContent = (
        <Box>
          <Typography>{nurse.cannot_join_affiliate_programme_reason}</Typography>
          <Button
            variant='contained'
            size='small'
            color='primary'
            component={RouterLink}
            to={nurseSignupURL()}>
            Click here to continue signup.
          </Button>
        </Box>
      )
      showAlert('info', alertContent)
    } else {
      navigate(nurseAffiliateSignupURL(nurse.nurse_id))
    }
  }, [navigate, nurse, showAlert])

  if (!nurse) return null

  if (isEmpty(days)) {
    return <Loading />
  }

  const isIndexPage = matchIndexPage !== null

  return (
    <Box sx={{
      display: ['block', isIndexPage ? 'block' : 'grid'],
      gridTemplateColumns: '1fr 1.5fr',
      gridTemplateAreas: `'left right'`,
      gap: 4,
    }}>
      <Box sx={{
        gridArea: 'left',
        display: [isIndexPage ? 'flex' : 'none', 'flex'],
        flexDirection: 'column',
        gap: 1,
        maxWidth: ['auto', isIndexPage ? '40%' : 'auto'],
        margin: [0, isIndexPage ? 'auto' : 0]
      }}>
        {!authUser?.isStaff ? (
          nurse.is_suspended ? (
            <Alert variant='filled' severity='error'>
              Your account is currently suspended. <br/>
              <Button
                variant='contained'
                size='small'
                color='warning'
                component={RouterLink}
                to={nurseSuspensionPageURL(nurse.nurse_id)}>
                Click here for more information.
              </Button>
            </Alert>
          ) : !nurse.approved ? (
            <Alert variant='filled' severity='info'>
              Your account is not fully setup yet. <br/>
              <Button
                variant='contained'
                size='small'
                component={RouterLink}
                to={nurseSignupURL()}>
                Click here to continue signup.
              </Button>
            </Alert>
          ) : nurse.prompt_for_extended_compliance ? (
            <Alert variant='filled' severity='info'>
              Some practices around your area require an extended level of compliance. <br/>
              To qualify for these jobs, they require your CPR and Infection Control. <br/>
              <Button
                variant='contained'
                size='small'
                component={RouterLink}
                to={nurseProfileURL(nurse.nurse_id, 'extended-compliance')}>
                Apply for extended compliance
              </Button>
            </Alert>
          ) : nurse.prompt_for_right_to_work ? (
            <Alert variant='filled' severity='info'>
              Some practices around your area require proof of right to work in the UK <br />
              <Button
                variant='contained'
                size='small'
                component={RouterLink}
                to={nurseProfileURL(nurse.nurse_id, 'right-to-work')}>
                Submit proof of right to work
              </Button>
            </Alert>
          ) : null
        ) : null}
        {!nurse.notify_job_posts_via_telegram ? (
          <Alert variant='filled' severity='info'>
            Receive pop-up notifications for new jobs on your phone via Telegram! <br/>
            <Button
              sx={{ marginTop: 1 }}
              variant='contained'
              size='small'
              component={RouterLink}
              to={nurseNotificationSettings(nurse.nurse_id)}>
              Setup Now
            </Button>
          </Alert>
        ) : null}
        <Calendar
          interval={interval}
          toNextMonth={toNextMonth}
          toPreviousMonth={toPreviousMonth}
          toCurrentMonth={toCurrentMonth}>
          {days.map(day => (
            <CalendarDay
              key={day.key}
              weekDayNumber={day.weekdaynum}
              weekNumber={day.weeknum}
              dayOfMonth={day.dayOfMonth?.toString() || ''}
              sx={{ backgroundColor: day.backgroundColor }}>
              <NurseCalendarDay day={day} />
            </CalendarDay>
          ))}
        </Calendar>
        { !nurse.is_affiliate ? (
          <Paper sx={{ padding: 2 }}>
            <Typography>
              Do you know any practices that should be using locumloop? <br/>
              Join our affiliate scheme and you could earn when they post jobs. <br/>
            </Typography>
            <Button onClick={onJoinAffiliateProgramme}>
              Join Now
            </Button>
          </Paper>
        ) : null}
        <Paper sx={{ display: 'flex', flexDirection: 'row', gap: 1, padding: 2, alignItems: 'center' }}>
          <Typography sx={{ flex: 1 }}>Percentage of jobs cancelled by you</Typography>
          <Typography>{ nurse.cancel_percentage_label }</Typography>
        </Paper>
        <Typography variant='body2'>
          There is a fee if you cancel a job which starts within 5 days from now. <br />
          0 - 24 hours, £40 fee <br />
          24 hours - 5 days, £20 fee <br />
          This is because when a job is cancelled within 5 days of the start time, it can be difficult to find a replacement nurse and there are associated costs.<br />
          If you do not go to a booked job we will suspend your account indefinitely. <br />
          Jobs cancelled by you: {nurse.cancel_count_overall}
        </Typography>
      </Box>
      <Box sx={{ gridArea: 'right' }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  )
}

export default NurseJobs