import { Box } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'

const AdminNurseProfile: React.FC = () => {
  const { nurse } = React.useContext(AdminNurseDetailContext)
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
        label: 'ID',
        value: nurse.nurse_id.toString(),
      }, {
        label: 'Name',
        value: `${nurse.full_name} (Name on ID: ${nurse.verified_full_name || 'N/A'})`,
      }, {
        label: 'Email',
        value: nurse.email,
      }, {
        label: 'Phone',
        value: nurse.phone_number,
      }, {
        label: 'Tier',
        value: nurse.tier_label
      }, {
        label: 'GDC Number',
        value: nurse.gdc_number || 'N/A',
      }, {
        label: 'Stripe Account ID',
        value: nurse.stripe_user_id || 'N/A'
      }, {
        label: 'Address',
        value: nurse.address,
        sx: { whiteSpace: 'pre-line' }
      }, {
        label: 'Sign Up At',
        value: nurse.signup_at_label,
      }, {
        label: 'Travel Radius',
        value: `${nurse.radius_miles} miles`,
      }, {
        label: 'Enabled',
        value: nurse.enabled ? 'Yes': 'No',
      }, {
        label: 'Agreed Contracts',
        value: nurse.agreed_contracts ? 'Yes': 'No',
      }, {
        label: 'Hours Worked',
        value: `${nurse.hours_worked} hours`
      }, {
        label: 'Jobs Worked',
        value: `${nurse.jobs_worked} jobs`
      }, {
        label: 'Hourly Rate',
        value: nurse.paygrade_label
      }, {
        label: 'Suspension Until',
        value: nurse.suspended_until?.toISO() ?? 'N/A'
      }, {
        label: 'Suspension Note',
        value: nurse.penalty_note
      }, {
        label: 'Require Late Cancellation Fee',
        value: nurse.require_late_cancellation_fee ? 'Yes' : 'No'
      }, {
        label: 'Affiliate',
        value: `${nurse.affiliate_code || 'Not Joined Affiliate Programme'} (Affiliate ID: ${nurse.affiliate_id || 'N/A'})`
      }, {
        label: 'ID Verification',
        value: `${nurse.verification_status || 'Not Submitted'} (Stripe Report ID: ${nurse.verification_report_id || 'N/A'})`
      }, {
        label: 'ID Document',
        value: `${nurse.id_document_type || 'Not Submitted'} (Country: ${nurse.id_issuing_country || 'N/A'})`
      }]} />
    </Box>
  )
}

export default AdminNurseProfile