import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface IDialogContext {
  showDialog: (title: string, content: React.ReactNode, action?: React.ReactNode) => void
  closeDialog: () => void
}

const DialogContext = React.createContext<IDialogContext>({} as IDialogContext)

const DialogProvider: React.FC = ({ children }) => {
  const [title, setTitle] = React.useState<string>('')
  const [content, setContent] = React.useState<React.ReactNode>()
  const [action, setAction] = React.useState<React.ReactNode>()

  const showDialog = React.useCallback((title: string, content: React.ReactNode, action?: React.ReactNode) => {
    setTitle(title)
    setContent(content)
    setAction(action)
  }, [])

  const closeDialog = React.useCallback(() => {
    setTitle('')
    setContent(undefined)
    setAction(undefined)
  }, [])

  const open = React.useMemo(() => content !== undefined, [content])

  const contextValue = { showDialog, closeDialog }

  return (
    <DialogContext.Provider value={contextValue}>
      <React.Fragment>
      <Dialog open={open} onClose={closeDialog}>
          <DialogTitle sx={{ whiteSpace: 'pre-line' }}>
            { title }
          </DialogTitle>
          <DialogContent>
            { content }
          </DialogContent>
          <DialogActions sx={{ display: 'flex', gap: 1 }}>
            {action}
            <Button 
              variant='outlined'
              onClick={closeDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {children}
      </React.Fragment>
    </DialogContext.Provider>
  )
}

function useDialog() {
  const context = React.useContext(DialogContext)
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context
}

export { DialogProvider, useDialog }