import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import { isEmpty } from 'lodash'
import Header from 'components/Header'
import { DOCUMENTATIONS_LABEL_MAPPING } from 'types/constants'
import ErrorList from 'components/ErrorList'
import CustomEmail from 'components/CustomEmail'
import useDocumentVerify from './hooks/useDocumentVerify'
import DocumentDisplay from './components/DocumentDisplay'
import VerifyForm from './components/VerifyForm'
import PageSelector from './components/PageSelector'
import { NurseProfileForm } from 'types/interfaces'
import NurseProfileError from 'errors/NurseProfileError'
import update from 'immutability-helper'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import FormField from 'components/FormField'

const AdminNurseGDCVerify: React.FC = () => {
    const { 
        docType, 
        files, 
        nurse, 
        form, 
        updateForm, 
        error,
        loading, 
        deleting, 
        save: saveVerifyForm, 
        deleteFile, 
        scrollToFile, 
        futureBookings,
        fetchFiles,
        fetchNurse,
        setLoading,
    } = useDocumentVerify()
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()

    const [nurseForm, setNurseForm] = React.useState<NurseProfileForm>({})
    const [nurseError, setNurseError] = React.useState<NurseProfileError>()
    const [downloadingGDC, setDownloadingGDC] = React.useState<boolean>(false)

    const updateNurseForm = React.useCallback((name: string, value: any) => {
        setNurseForm(update(nurseForm, { [name]: { $set: value } }))
        if (nurseError) setNurseError(update(nurseError, { [name]: { $set: [] } }))
    }, [nurseError, nurseForm])

    const downloadGDC = React.useCallback(async () => {
        try {
          setDownloadingGDC(true)
          await api.downloadGDCScreenshot(nurse.nurse_id)
          await fetchFiles()
        } catch (e) {
          showAlert('error', 'Failed to download GDC screenshot')
          console.error(e)
        } finally {
          setDownloadingGDC(false)
        }
    }, [api, fetchFiles, nurse.nurse_id, showAlert])

    const save = React.useCallback(async () => {
        await saveVerifyForm()
        try {
            setLoading(true)
            await api.editNurse(nurseForm, nurse.nurse_id)
            await fetchNurse()
          } catch (e) {
            if (e instanceof NurseProfileError) {
              setNurseError(e)
            } else {
              console.error('uncaught exception', e)
            }
          } finally {
            setLoading(false)
          }
    }, [api, fetchNurse, nurse.nurse_id, nurseForm, saveVerifyForm, setLoading])

    React.useEffect(() => {
        setNurseForm({
            gdc_number: nurse.gdc_number,
            gdc_registration_name: nurse.gdc_registration_name,
            gdc_registration_status: nurse.gdc_registration_status,
            gdc_registration_start_at: nurse.gdc_registration_start_at,
            gdc_registration_end_at: nurse.gdc_registration_end_at,
        })
    }, [nurse])

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            gridTemplateAreas: `'image form'`,
            gap: 2
        }}>
            <Box sx={{ gridArea: 'image', alignSelf: 'center' }}>
                <DocumentDisplay files={files} />
            </Box>
            <Box sx={{ gridArea: 'form', display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Header variant='h3' text='GDC Certificate'></Header>
                <VerifyForm form={form} updateForm={updateForm} error={error} docType={docType} />
                <FormField
                    name='gdc_number'
                    label='GDC Number'
                    onChange={(e) => updateNurseForm('gdc_number', e.target.value)}
                    value={nurseForm.gdc_number ?? ''}
                    errors={nurseError?.gdc_number}
                />
                <FormField
                    name='gdc_registration_name'
                    label='GDC Registration Name'
                    onChange={(e) => updateNurseForm('gdc_registration_name', e.target.value)}
                    value={nurseForm.gdc_registration_name ?? ''}
                    errors={nurseError?.gdc_registration_name}
                />
                <FormField
                    name='gdc_registration_status'
                    label='GDC Registration Status'
                    onChange={(e) => updateNurseForm('gdc_registration_status', e.target.value)}
                    value={nurseForm.gdc_registration_status ?? ''}
                    errors={nurseError?.gdc_registration_status}
                />
                <FormField
                    name='gdc_registration_start_at'
                    label='Registration Start Date'
                    type='date'
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => updateNurseForm('gdc_registration_start_at', e.target.value)}
                    value={nurseForm.gdc_registration_start_at ?? ''}
                    errors={nurseError?.gdc_registration_start_at}
                />
                <FormField
                    name='gdc_registration_end_at'
                    label='Registration End Date'
                    type='date'
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => updateNurseForm('gdc_registration_end_at', e.target.value)}
                    value={nurseForm.gdc_registration_end_at ?? ''}
                    errors={nurseError?.gdc_registration_end_at}
                />
                {error?.schema ? (
                    <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
                ) : null}
                <Button variant="outlined" color="primary" onClick={downloadGDC} disabled={downloadingGDC}>
                    {downloadingGDC ? 'Please Wait ...' : 'Refresh GDC Screenshot'}
                </Button>
                <Button variant="contained" color="primary" onClick={save} disabled={loading}>
                    {loading ? 'Please Wait ...' : 'Update Document Status'}
                </Button>
                {!form.approved && !isEmpty(futureBookings) ? (
                    <Alert variant='filled' severity='warning'>
                        This nurse has jobs booked in the future. <br />
                        If you disapprove this nurse, remember to cancel those bookings and find replacements for those jobs.
                    </Alert>
                ) : null}
                <PageSelector files={files} scrollToFile={scrollToFile} deleteFile={deleteFile} deleting={deleting} />
                <CustomEmail
                    nurse={nurse}
                    subject='Action required before we can approve your account'
                />
            </Box>
        </Box>
    )
}

export default AdminNurseGDCVerify