import { Alert } from '@mui/material'
import { Expose, Type, Transform } from 'class-transformer'
import { DateTime, Duration } from 'luxon'
import { JobFulfillmentStatus, JobTimesheetStatus, JobPaymentStatus, PaymentOption, NurseTier } from 'types/types'
import { fromKmToMiles } from './helpers'
import JobEmployment from './JobEmployment'
import { chain, isArray } from 'lodash'

export default class Job {
  job_id!: number
  practice_name!: string
  practice_tz: string = 'Europe/London'
  practice_id!: number
  practice_group_id!: number
  practice_group_name!: string
  phone_number!: string
  address!: string
  line1!: string
  line2!: string
  city!: string
  county!: string
  country!: string
  postcode!: string

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  end_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  cancel_at!: DateTime | null

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  published_at!: DateTime | null

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  lunch_break!: Duration

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  countdown!: Duration

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  duration!: Duration

  headcount!: number
  description!: string
  can_edit!: boolean
  can_cancel!: boolean
  can_take!: boolean
  cannot_cancel_reason!: string
  cannot_take_reason!: string

  filled_employment_count!: number
  starting_soon_employment_count!: number
  cancelled_by_nurse_employment_count!: number
  payment_required_employment_count!: number
  payment_requested_employment_count!: number
  payment_success_employment_count!: number
  payment_failed_employment_count!: number
  timesheet_change_pending_employment_count!: number
  fulfillment_status!: JobFulfillmentStatus
  payment_status!: JobPaymentStatus
  timesheet_status!: JobTimesheetStatus
  employments!: JobEmployment[]

  // practice first member
  practice_first_member_user_id!: number
  practice_first_member_user_first_name!: string
  practice_first_member_user_last_name!: string
  practice_first_member_user_email!: string
  practice_first_member_position!: string

  // vetting
  is_possible_to_vet_practice_before_publishing_job!: boolean

  distance_km?: number
  practice_payment_method_available!: boolean
  payment_option?: PaymentOption

  // special requirements
  document_sharing_required!: boolean
  extended_compliance_required!: boolean
  scotland_compliance_required!: boolean

  // Estimated payment dates
  @Type(() => DateTime)
  @Transform(({ value }) => {
    if(isArray(value)) {
      return value.map((v) => DateTime.fromISO(v))
    }
    return null
  })
  estimated_payment_date!: DateTime[] | null

  // Tiering
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  open_to_good_tier_at!: DateTime | null

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  open_to_new_tier_at!: DateTime | null

  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  open_to_bad_tier_at!: DateTime | null

  is_open_to_good_tier!: boolean
  is_open_to_new_tier!: boolean
  is_open_to_bad_tier!: boolean

  @Expose()
  get practice_label() {
    return this.practice_group_id ? `${this.practice_name} (${this.practice_group_name})` : this.practice_name
  }

  @Expose()
  get distance_label() {
    if (this.distance_km !== undefined) {
      let distance_miles = fromKmToMiles(this.distance_km, 2)
      return `${distance_miles} miles away`
    } else {
      return ''
    }
  }

  get first_member_label() {
    return `${this.practice_first_member_user_first_name} ${this.practice_first_member_user_last_name} (${this.practice_first_member_user_email})`
  }

  is_open_to_tier_label(tier: NurseTier, tz: string = 'Europe/London') {
    let open_at, is_opened
    if (tier === 'good') {
      open_at = this.open_to_good_tier_at?.setZone(tz).toFormat('ccc, LLL dd h:mm a')
      is_opened = this.is_open_to_good_tier
    } else if (tier === 'new' || tier === 'unapproved') {
      open_at = this.open_to_new_tier_at?.setZone(tz).toFormat('ccc, LLL dd h:mm a')
      is_opened = this.is_open_to_new_tier
    } else if (tier === 'bad') {
      open_at = this.open_to_bad_tier_at?.setZone(tz).toFormat('ccc, LLL dd h:mm a')
      is_opened = this.is_open_to_bad_tier
    }
    if (is_opened) {
      return `Yes. Opened at ${open_at}`
    } else {
      return `No. Will open at ${open_at}`
    }
  }

  get opened_to_tiers_label() {
    let tiers: NurseTier[] = []
    if (this.is_open_to_good_tier) {
      tiers.push('good')
    }
    if (this.is_open_to_new_tier) {
      tiers.push('new')
    }
    if (this.is_open_to_bad_tier) {
      tiers.push('bad')
    }
    return tiers.join(', ')
  }

  date_label(tz: string) {
    return this.start_at.setZone(tz).toFormat('ccc, LLL dd')
  }

  time_label(tz: string) {
    return this.start_at.setZone(tz).toFormat('h:mm a') + ' - ' + this.end_at.setZone(tz).toFormat('h:mm a')
  }

  countdown_label() {
    return this.countdown.shiftTo('days', 'hours', 'minutes').toHuman({ unitDisplay: 'short' })
  }

  posted_at_label(tz: string) {
    return this.created_at.setZone(tz).toFormat('ccc, LLL dd h:mm a')
  }

  published_at_label(tz: string) {
    return this.published_at?.setZone(tz).toFormat('ccc, LLL dd h:mm a')
  }

  cancel_at_label(tz: string) {
    return this.cancel_at?.setZone(tz).toFormat('ccc, LLL dd h:mm a')
  }

  @Expose()
  get employment_labels() {
    return this.employments.map(employment => {
      return `${employment.nurse_first_name} ${employment.nurse_last_name}`
    })
  }

  @Expose()
  get filled_ratio_label() {
    return `${this.filled_employment_count}/${this.headcount} filled`
  }

  @Expose()
  get paid_ratio_label() {
    return `${this.payment_success_employment_count}/${this.headcount} paid`
  }

  @Expose()
  get duration_label() {
    return this.duration.shiftTo('hours').toHuman({ unitDisplay: 'short' })
  }

  estimated_earnings(hourly_rate: number) {
    let hours = this.duration.as('hours')
    let earnings = hourly_rate * hours
    return `£${earnings.toFixed(2)}`
  }

  estimated_earnings_detail(hourly_rate: number) {
    let hours = this.duration.as('hours')
    let earnings = this.estimated_earnings(hourly_rate)
    return `${earnings} (£${hourly_rate} per hour, for ${hours} hours)`
  }

  @Expose()
  get lunch_break_label() {
    return `${this.lunch_break.as('minutes')} minutes lunch break`
  }

  @Expose()
  get outstanding() {
    return this.headcount - this.filled_employment_count
  }

  @Expose()
  get unavailable_message() {
    if (this.fulfillment_status === 'filled') {
      return 'Sorry this job has already been filled'
    } else if (this.fulfillment_status === 'cancelled') {
      return 'Sorry this job has been cancelled'
    } else if (this.end_at <= DateTime.local()) {
      return 'This job has already ended'
    } else if (this.start_at <= DateTime.local()) {
      return 'This job has already started. It is too late to apply now'
    }
    return ''
  }

  @Expose()
  get fulfillment_status_color() {
    switch (this.fulfillment_status) {
      case 'cancelled':
        return 'error'
      case 'filled':
        return 'success'
      case 'partially_filled':
      case 'not_filled':
        return 'warning'
      default:
        return 'default'
    }
  }

  @Expose()
  get payment_status_color() {
    switch (this.payment_status) {
      case 'practice_payment_pending':
      case 'practice_invoice_finalized':
        return 'warning'
      case 'practice_payment_success':
      case 'practice_invoice_paid':
        return 'success'
      case 'practice_payment_failed':
      case 'practice_invoice_void':
        return 'error'
      default:
        return 'default'
    }
  }

  @Expose()
  get timesheet_status_color() {
    switch (this.timesheet_status) {
      case 'timesheet_pending_approval':
        return 'error'
      default:
        return 'default'
    }
  }

  @Expose()
  get status_alert() {
    if (this.fulfillment_status === 'cancelled') {
      return (
        <Alert severity='info' variant='filled'>
          You have cancelled this job.<br/>
        </Alert>
      )
    }
    return null
  }

  @Expose()
  get status_for_practice_signup_page() {
    if (!this.published_at) {
      return 'not-published'
    } else if (this.fulfillment_status === 'filled' || this.fulfillment_status === 'partially_filled') {
      return 'filled'
    } else if (this.start_at <= DateTime.now()) {
      return 'expired'
    } else {
      return 'finding-nurse'
    }
  }

  @Expose()
  get payment_duration_label() {
    return (
      chain(this.estimated_payment_date ?? [])
      .map(date => date.toFormat('LLL dd'))
      .join(' to ')
      .value()
    )
  }

  @Expose()
  get payment_duration_explanation() {
    switch (this.payment_option) {
      case 'pay_eod_with_card':
        return `
        Practice pays by card as soon as the job completes.
        It usually takes between 1 - 7 days to process a card payment.
        This may take longer for first-time users.`
      case 'pay_eod_with_bacs_debit':
        return `
        Practice pays by BACS debit as soon as the job completes.
        It usually takes between 7 - 14 days to process a BACS debit payment.
        This may take longer for first-time users.`
      case 'invoice_weekly_autopay_with_card':
        return `
        Practice pays by card automatically at the end of the week.
        It usually takes between 1 - 7 days to process a card payment.
        This may take longer for first-time users.`
      case 'invoice_weekly_autopay_with_bacs_debit':
        return `
        Practice pays by BACS debit automatically at the end of the week.
        It usually takes between 7 - 14 days to process a BACS debit payment.
        This may take longer for first-time users.`
      case 'invoice_weekly_pay_manually':
        return `
        Practice is invoiced at the end of the week. 
        The practice then have 7 days to pay the invoice.`
      case 'invoice_biweekly_autopay_with_card':
        return `
        Practice pays by card automatically on the 1st and 15th of each month.
        It usually takes between 1 - 7 days to process a card payment.
        This may take longer for first-time users.`
      case 'invoice_biweekly_autopay_with_bacs_debit':
        return `
        Practice pays by BACS debit automatically on the 1st and 15th of each month.
        It usually takes between 7 - 14 days to process a BACS debit payment.
        This may take longer for first-time users.`
      case 'invoice_biweekly_pay_manually':
        return `
        Practice is invoiced on the 1st and 15th of each month. 
        The practice then have 7 days to pay the invoice.`
      case 'invoice_monthly_autopay_with_card':
        return `
        Practice pays by card automatically at the end of the month.
        It usually takes between 1 - 7 days to process a card payment.
        This may take longer for first-time users.`
      case 'invoice_monthly_autopay_with_bacs_debit':
        return `
        Practice pays by BACS debit automatically at the end of the month.
        It usually takes between 7 - 14 days to process a BACS debit payment.
        This may take longer for first-time users.`
      case 'invoice_monthly_pay_manually':
        return `
        Practice is invoiced at the end of the month.
        The practice then have 7 days to pay the invoice.
        Card payments are quick. Direct Debits may be slower.`
      default:
        return ''
    }
  }

  get fulfillment_status_label() {
    if (this.fulfillment_status === 'cancelled') {
      return 'Cancelled'
    } else if (this.fulfillment_status === 'filled') {
      return 'Filled'
    } else if (this.fulfillment_status === 'partially_filled') {
      return 'Partially Filled'
    } else if (this.fulfillment_status === 'not_filled') {
      return 'Not Filled'
    } else {
      return ''
    }
  }

  get payment_status_label() {
    if (['practice_payment_success', 'practice_invoice_paid'].includes(this.payment_status)) {
      return 'Paid'
    } else if (['practice_payment_failed', 'practice_invoice_void'].includes(this.payment_status)) {
      return 'Payment Failed'
    } else if (this.payment_status === 'practice_payment_pending') {
      return 'Payment Pending'
    } else if (this.payment_status === 'practice_invoice_finalized') {
      return 'Invoice Sent'
    } else {
      return ''
    }
  }

  get timesheet_status_label() {
    if (this.timesheet_status === 'timesheet_pending_approval') {
      return 'Timesheet Pending Approval'
    } else {
      return ''
    }
  }
}