import { Expose } from 'class-transformer';
import AddressFormError from 'errors/AddressFormError';

export default class NurseProfileError extends AddressFormError {
  first_name: string[] = []
  last_name: string[] = []
  phone_number: string[] = []
  notify_registration_prompts: string[] = []
  notify_job_posts_via_email_sms: string[] = []
  notify_job_posts_via_telegram: string[] = []
  agreed_contracts?: string[] = []
  @Expose({ name: 'radius_km' }) radius: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
  rtw_sharecode: string[] = []
  rtw_dob: string[] = []
  gdc_number: string[] = []

  // admin only
  num_cancellations: string[] = []
  gdc_registration_name: string[] = []
  gdc_registration_status: string[] = []
  gdc_registration_start_at: string[] = []
  gdc_registration_end_at: string[] = []
  indemnity_insurance_date: string[] = []
  hepatitis_b_vaccination_date: string[] = []
  hepatitis_c_date: string[] = []
  tb_date: string[] = []
  hiv_date: string[] = []
  dbs_date: string[] = []
  pvg_date: string[] = []
  infection_control_date: string[] = []
  cpr_date: string[] = []
  rtw_file: string[] = []
  rtw_date: string[] = []
  rtw_reason: string[] = []
  rtw_approved: string[] = []
  suspended_until: string[] = []
  penalty_note: string[] = []
  require_late_cancellation_fee: string[] = []
}