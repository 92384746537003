import { Box, Button, ButtonGroup } from '@mui/material'
import { useAdmin } from 'contexts/AdminProvider'
import React from 'react'
import Header from 'components/Header'
import AdminNurseTable from 'components/AdminNurseTable'
import { GridRowParams } from '@mui/x-data-grid'
import Nurse from 'logic/Nurse'
import { useNavigate } from 'react-router'
import { adminNurseDetailURL } from 'routes/urls'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { Subject } from 'rxjs'

const AdminNurseList: React.FC = () => {
  const navigate = useNavigate()
  const { 
    nurseSearchForm, 
    nurseSelection, 
    nurseColumnVisibilityModel, 
    nurseSortModel, 
    updateNurseSearchForm, 
    setNurseSelection, 
    setNurseColumnVisibilityModel, 
    setNurseSortModel 
  } = useAdmin()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  
  const reload$ = React.useMemo(() => new Subject<boolean>(), [])

  const onRowClick = React.useCallback((params: GridRowParams<Nurse>) => {
    navigate(adminNurseDetailURL(params.row.nurse_id))
  }, [navigate])

  const waitlist = React.useCallback(async (action: 'join' | 'unjoin') => {
    if (nurseSelection.length === 0) {
      showAlert('error', 'Select a nurse first')
      return
    }
    const nurseIds = nurseSelection as number[]
    
    try {
      await api.nurseWaitlist({ nurse_ids: nurseIds, waitlist: 'cpd', action: action })
      reload$.next(true)
      showAlert('success', `${action} CPD waitlist success`)
    } catch (e) {
      showAlert('error', `${action} CPD waitlist failed`)
    }
  }, [api, nurseSelection, reload$, showAlert])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Nurse List' />
      <AdminNurseTable
        reload$={reload$}
        onRowClick={onRowClick}
        actions={
          <ButtonGroup size='small'>
            <Button variant='outlined' onClick={() => waitlist('join')}>
              Add CPD
            </Button>
            <Button variant='outlined' onClick={() => waitlist('unjoin')}>
              Remove CPD
            </Button>
          </ButtonGroup>
        }
        form={nurseSearchForm}
        updateForm={updateNurseSearchForm}
        selection={nurseSelection}
        setSelection={setNurseSelection}
        columnVisibilityModel={nurseColumnVisibilityModel}
        setColumnVisibilityModel={setNurseColumnVisibilityModel}
        sortModel={nurseSortModel}
        setSortModel={setNurseSortModel}
      />
    </Box>
  )
}

export default AdminNurseList