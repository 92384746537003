import { MenuItem } from '@mui/material'
import FormField from 'components/FormField'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
    adminNurseLocationReportURL,
    adminPracticeReportURL,
    adminChartsURL,
} from 'routes/urls'

const OPTIONS = [{
    url: adminNurseLocationReportURL(),
    label: 'Nurse Count By Location',
}, {
    url: adminPracticeReportURL(),
    label: 'Practice',
}, {
    url: adminChartsURL(),
    label: 'Charts',
}]

const ReportSelectBox: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [selection, setSelection] = React.useState<string>(location.pathname)

    React.useEffect(() => {
        if (selection !== location.pathname) {
            navigate(selection)
        }
    }, [navigate, selection])
    
    return (
        <FormField
            sx={{ width: 300 }}
            label='Go To Report'
            select
            onChange={(e) => setSelection(e.target.value)}
            value={selection}>
              {OPTIONS.map((option) => (
                <MenuItem key={option.url} value={option.url}>
                  {option.label}
                </MenuItem>
              ))}
        </FormField>
    )
}

export default ReportSelectBox